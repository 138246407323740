import { useEffect, useCallback } from "react";
import { getParticipantInfo as fetchParticipantInfo } from "../httpapi";

import { useAppStateContext, actions } from "../providers/AppStateProvider";

const {
  ADD_PARTICIPANT_INFO_FAILURE,
  ADD_PARTICIPANT_INFO_SUCCESS,
  ADD_PARTICIPANT_INFO_LOADING,
} = actions;

const useParticipantInfo = id => {
  const { state, dispatch } = useAppStateContext();
  const { participantInfo } = state;

  const getData = useCallback(
    async participantId => {
      dispatch({ type: ADD_PARTICIPANT_INFO_LOADING, participantId });
      const result = await fetchParticipantInfo(participantId);
      if (result.success === false) {
        dispatch({ type: ADD_PARTICIPANT_INFO_FAILURE, participantId });
        return;
      }
      dispatch({
        type: ADD_PARTICIPANT_INFO_SUCCESS,
        participantId,
        data: result,
      });
    },
    [dispatch],
  );

  useEffect(() => {
    if (!id || participantInfo[id]) {
      return;
    }
    getData(id);
  }, [participantInfo, getData, id]);

  const participant = participantInfo[id] || {};

  const getDisplayName = () => {
    switch (participant.status) {
      case "loading":
        return "";
      case "error":
        return "User";
      case "success":
        return participant.displayName;
      default:
        return "";
    }
  };
  const displayName = getDisplayName();

  const getPhotoUrl = () => {
    switch (participant.status) {
      case "loading":
        return "";
      case "error":
        return "";
      case "success":
        return participant.photoUrl;
      default:
        return "";
    }
  };
  const photoUrl = getPhotoUrl();

  const getParticipantType = () => {
    switch (participant.status) {
      case "loading":
        return "";
      case "error":
        return "";
      case "success":
        return participant.participantType;
      default:
        return "";
    }
  };
  const participantType = getParticipantType();

  return {
    displayName,
    photoUrl,
    participantType,
    status: participant.status,
  };
};

export default useParticipantInfo;
