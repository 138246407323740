import React from "react";
import styled from "styled-components";
import NavItem from "./NavItem";
import useCopy from "../../hooks/useCopy";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";
import { useAppStateContext } from "../../providers/AppStateProvider";
import { datadogEvent } from "../../datadog";

const MemberLink = styled.a`
  display: flex;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.default};
  padding: 20px 16px;
  font-size: ${({ theme }) => theme.fontSize.normal};
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: none;
  }

  img {
    border-radius: 3px;
    width: 40px;
    margin-right: 10px;
  }
`;

const ItemText = styled.div`
  display: inline-block;
  margin-top: 2px;
`;

const Counter = styled.div`
  position: absolute;
  display: inline-block;
  margin-left: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSize.small};
  color: white;
  line-height: 20px;
  text-align: center;
`;

const Menu = ({ activePage, onClick }) => {
  const { room } = useWaitingRoomContext();
  const {
    state: { waitingRoom },
  } = useAppStateContext();
  const { participants: waitingParticipants } = waitingRoom;
  const [waitingRoomText, historyText, settingsText, helpText] = useCopy([
    "navItem.waitingRoom",
    "navItem.sessionHistory",
    "navItem.settings",
    "navItem.help",
  ]);

  const itemClicked = featureName => {
    datadogEvent({
      category: "navigation",
      feature: featureName,
      event: "clicked",
      component: "SidebarLayout.Menu",
    });

    onClick && onClick();
  };

  const backToAccount = () => {
    datadogEvent({
      category: "navigation",
      feature: "back_to_account",
      event: "clicked",
      component: "SidebarLayout.Menu",
    });

    window.location.href = `https://member.psychologytoday.com/${room.countryCode.toLowerCase()}/home`;
  };

  return (
    <>
      <NavItem
        active={activePage === "/dashboard"}
        text={
          activePage !== "/dashboard" && waitingParticipants.length > 0 ? (
            <>
              <ItemText>{waitingRoomText}</ItemText>
              <Counter>{waitingParticipants.length}</Counter>
            </>
          ) : (
            waitingRoomText
          )
        }
        path="/dashboard"
        onClick={() => itemClicked("dashboard")}
      />
      <NavItem
        active={activePage === "/dashboard/history"}
        text={historyText}
        path="/dashboard/history"
        onClick={() => itemClicked("session_history")}
      />
      <NavItem
        active={activePage === "/dashboard/settings"}
        text={settingsText}
        path="/dashboard/settings"
        onClick={() => itemClicked("room_settings")}
      />
      <NavItem
        active={activePage === "/dashboard/help"}
        text={helpText}
        path="/dashboard/help"
        onClick={() => itemClicked("help")}
      />
      <MemberLink href="#" onClick={backToAccount}>
        <img src={room.therapistPhotoUrl} alt={room.therapistDisplayName} />
        <div>{room.therapistDisplayName}</div>
      </MemberLink>
    </>
  );
};

export default Menu;
