import React from "react";
import styled from "styled-components";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ImageWrapper = styled.img`
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
`;

const Name = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.normal};
`;

function TherapistInfo() {
  const { room: waitingRoom } = useWaitingRoomContext();

  return (
    <Wrapper>
      <ImageWrapper src={waitingRoom.therapistPhotoUrl} />
      <Name>{waitingRoom.therapistDisplayName}</Name>
    </Wrapper>
  );
}

export default TherapistInfo;
