import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Panel from "../Panel";
import useCopy from "../../hooks/useCopy";
import { Button, SubmitButton, Anchor, H2 } from "@sussex/react-kit/elements";
import { requestUpdateRoom } from "../../httpapi";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";
import ErrorAlert from "../ErrorAlert";
import { datadogEvent } from "../../datadog";

const Wrapper = styled.div`
  margin-bottom: 30px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  > * {
    margin-right: 10px;
  }
`;

const LinkWrapper = styled.div`
  a {
    ${Anchor.componentStyle.rules}

    @media (min-width: 768px) {
      white-space: nowrap;
    }
  }
`;

const Body = styled.div`
  display: flex;
  word-break: break-word;
  & > * {
    flex: 0 1 auto;
  }
`;

function RoomSetup() {
  const [networkError, setNetworkError] = useState(false);
  const { room, setRoom, getRoomUrl } = useWaitingRoomContext();
  const [
    helloHeader,
    body,
    updateText,
    dismissText,
    networkErrorText,
  ] = useCopy([
    "setup.hello",
    "setup.body",
    "setup.update",
    "setup.dismiss",
    "setup.networkError",
  ]);
  const history = useHistory();
  const { roomAlias } = room;

  const update = () => {
    datadogEvent({
      category: "room_setup",
      feature: "update",
      event: "clicked",
      component: "RoomSetup",
    });

    history.push("/dashboard/settings");
  };

  const dismiss = async () => {
    datadogEvent({
      category: "room_setup",
      feature: "dismiss",
      event: "clicked",
      component: "RoomSetup",
    });

    // Dismiss regardless of network connectivity
    setRoom(state => {
      return { ...state, setupReady: true };
    });
    try {
      await requestUpdateRoom(roomAlias);

      datadogEvent({
        category: "room_settings",
        event: "saved",
        component: "RoomSetup",
      });
    } catch (err) {
      setNetworkError(true);
    }
  };

  if (networkError) {
    return (
      <Wrapper>
        <ErrorAlert message={networkErrorText} />
      </Wrapper>
    );
  }
  // Don't need to show anything if user already setup
  if (room.setupReady) {
    return null;
  }

  return (
    <Wrapper>
      <Panel>
        <div>
          <H2>{helloHeader}</H2>
          <Body>
            <div>
              <LinkWrapper
                dangerouslySetInnerHTML={{
                  __html: body.replace(
                    /\[LINK\]/g,
                    `<a href="${getRoomUrl()}" target="_blank">${getRoomUrl()}</a>`,
                  ),
                }}
              />
              <ButtonWrapper>
                <SubmitButton onClick={update}>{updateText}</SubmitButton>
                <Button variant="light" onClick={dismiss}>
                  {dismissText}
                </Button>
              </ButtonWrapper>
            </div>
          </Body>
        </div>
      </Panel>
    </Wrapper>
  );
}

export default RoomSetup;
