import React from "react";
import waitingAvatar from "../../assets/waiting-avatar.svg";
import styled, { css } from "styled-components";
import useCopy from "../../hooks/useCopy";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 50px;
  height: 50px;
  background: ${({ theme }) => theme.colors.default};
  border-radius: 50%;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.7;
    `};
`;

const Picture = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default function ClientPicture({ photo, disabled }) {
  const [placeholderAlt] = useCopy(["dashboard.waitingForClient"]);

  return photo ? (
    <Wrapper disabled={disabled}>
      <Picture src={photo} />
    </Wrapper>
  ) : (
    <img src={waitingAvatar} alt={placeholderAlt} />
  );
}
