import {
  WEBSOCKET_SET_CONNECT,
  WEBSOCKET_SET_CONNECTED,
  WEBSOCKET_SET_ERROR,
  SESSION_START,
  SESSION_END,
  WAITROOM_SET_PARTICIPANTS,
  WAITROOM_SET_DISPLAYNAME,
  WAITROOM_SET_THERAPIST_AVAILABLE,
  SET_MAIN_NAV,
  SET_ACCOUNT_NAV,
  ADD_PARTICIPANT_INFO_FAILURE,
  ADD_PARTICIPANT_INFO_SUCCESS,
  ADD_PARTICIPANT_INFO_LOADING,
} from "./actions";

const reducer = (state, action) => {
  switch (action.type) {
    case WEBSOCKET_SET_CONNECT:
      return {
        ...state,
        websocket: {
          ...state.websocket,
          connect: action.connect,
        },
      };
    case WEBSOCKET_SET_CONNECTED:
      return {
        ...state,
        websocket: {
          ...state.websocket,
          isConnected: action.isConnected,
        },
      };
    case WEBSOCKET_SET_ERROR:
      return {
        ...state,
        websocket: {
          ...state.websocket,
          error: action.error,
        },
      };
    case WAITROOM_SET_DISPLAYNAME:
      return {
        ...state,
        displayName: action.displayName,
      };
    case WAITROOM_SET_PARTICIPANTS:
      return {
        ...state,
        therapist:
          action.participants.find(x => x.participantType === "therapist") ||
          {},
        waitingRoom: {
          ...state.waitingRoom,
          participants: action.participants,
        },
      };
    case WAITROOM_SET_THERAPIST_AVAILABLE:
      return {
        ...state,
        waitingRoom: {
          ...state.waitingRoom,
          therapistAvailable: action.available,
          therapistInitialState: action.isInitialState,
        },
      };
    case SESSION_START:
      return {
        ...state,
        session: {
          ...state.session,
          token: action.token,
          startTime: action.startTime,
          active: true,
          ended: false,
        },
      };
    case SET_ACCOUNT_NAV:
      return {
        ...state,
        accountNav: action.nav,
      };
    case SET_MAIN_NAV:
      const children = action.nav.children.map(child => {
        /**
         * We need to "patch in" the sessions submenu into this unified nav
         * object. Set it to true. and we can render out a custom menu. Word on
         * the street from Rob and Steven Saari is that this will eventually go
         * away, and the submenu for sessions will come from the common nav
         * object. However we will have to deal with single SPA issues at that
         * point I guess.
         */
        if (child.id === "sessions") {
          return {
            ...child,
            children: true,
          };
        }
        return child;
      });

      const nav = {
        ...action.nav,
        children,
      };

      return {
        ...state,
        mainNav: nav,
      };
    case SESSION_END:
      return {
        ...state,
        session: {
          ...state.session,
          token: null,
          startTime: null,
          active: false,
          ended: true,
        },
      };
    case ADD_PARTICIPANT_INFO_SUCCESS:
      return {
        ...state,
        participantInfo: {
          ...state.participantInfo,
          [action.participantId]: {
            ...action.data,
            status: "success",
          },
        },
      };
    case ADD_PARTICIPANT_INFO_FAILURE:
      return {
        ...state,
        participantInfo: {
          ...state.participantInfo,
          [action.participantId]: {
            status: "error",
          },
        },
      };
    case ADD_PARTICIPANT_INFO_LOADING:
      return {
        ...state,
        participantInfo: {
          ...state.participantInfo,
          [action.participantId]: {
            status: "loading",
          },
        },
      };
    default:
      throw new Error("invalid action type");
  }
};

export default reducer;
