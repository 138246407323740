import React, { useState, useEffect, useRef } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Footer from "./Footer";
import styled from "styled-components";
import Panel from "../Panel";
import RoomSetup from "../RoomSetup";
import ClientList from "./ClientList";
import { AvailableToggle } from "../TherapistAvailability";
import LocalVideoPreview from "../LocalVideoPreview/LocalVideoPreview";
import useCopy from "../../hooks/useCopy";
import InviteToSessionModal from "../InviteToSessionModal";
import InviteSentNotification from "../InviteSentNotification";
import { WhatsNewBanner } from "../WhatsNew";
import DesktopNotificationsBanner from "../DesktopNotificationsBanner";
import VideoAndMicSettings from "../VideoAndMicSettings";
import { H1 } from "@sussex/react-kit/elements";
import useVideoContext from "../../hooks/useVideoContext";
import { useAppStateContext } from "../../providers/AppStateProvider";
import { requestStartSession, requestEndSession } from "../../httpapi";
import { datadogEvent } from "../../datadog";

const HeaderWrapper = styled.div`
  margin-bottom: 20px;
  @media (min-width: 960px) {
    display: flex;
    justify-content: space-between;
  }
`;

const AvailableWrapper = styled.div`
  margin-top: 15px;
  @media (min-width: 960px) {
    margin-top: 0;
    justify-self: flex-end;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: 960px) {
    flex-direction: row;
  }
`;

const Column = styled.div`
  width: 100%;
  margin-bottom: 10px;
  @media (min-width: 960px) {
    margin: 0;
    width: calc(50% - 10px);
  }
`;

const TherapistVideoPanel = ({ setStartSessionFailed }) => {
  const [waitingRoomText] = useCopy(["navItem.waitingRoom"]);
  const { isAcquiringLocalTracks, localTrackError } = useVideoContext();
  const [changingMediaSettings, setChangingMediaSettings] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [messageSentModal, setMessageSentModal] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [loadingIdentity, setLoadingIdentity] = useState("");
  const componentIsMounted = useRef(true);
  const xs = !useMediaQuery("(min-width:960px)");

  const {
    state: { websocket },
  } = useAppStateContext();

  const handleMessageSentModalClose = () => {
    setTimeout(() => {
      setMessageSentModal(null);
      setClientName(null);
    }, 10000);
  };

  const handleNotificationModalClose = (success, clientName) => {
    setShowModal(false);
    // Anything besids true/false, reject
    if (success !== true && success !== false) {
      return;
    }
    const modal = success ? "success" : "fail";
    setClientName(clientName);
    setMessageSentModal(modal);
  };

  const disableChat = !websocket.isConnected;

  const disableStart =
    isAcquiringLocalTracks || localTrackError || !websocket.isConnected;

  const startSession = async ({ participantId }) => {
    setStartSessionFailed(false);
    setLoadingIdentity(participantId);

    const result = await requestStartSession([participantId]);
    if (!result) {
      console.error("Something went wrong starting session");
      setLoadingIdentity("");
      setStartSessionFailed(true);
      return;
    }

    // wait a few seconds and if the component is still mounted,
    // it means that the session could not be started and in that
    // case show an error and end the session
    setTimeout(() => {
      if (componentIsMounted.current) {
        console.error("Therapist did not receive the session ready message");

        setLoadingIdentity("");
        setStartSessionFailed(true);
        requestEndSession();

        datadogEvent({
          category: "session",
          event: "ended",
          component: "TherapistVideoPanel",
        });
      }
    }, 10000);
  };

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const toggleMediaSettings = active => {
    setChangingMediaSettings(active);

    datadogEvent({
      category: "media_settings",
      feature: "panel",
      event: active ? "opened" : "closed",
      component: "TherapistVideoPanel",
    });
  };

  return (
    <>
      <WhatsNewBanner />
      <DesktopNotificationsBanner />
      <RoomSetup />
      <InviteSentNotification
        message={messageSentModal}
        name={clientName}
        close={handleMessageSentModalClose}
      />
      {showModal && (
        <InviteToSessionModal close={handleNotificationModalClose} />
      )}
      <VideoAndMicSettings
        active={changingMediaSettings}
        overlap={true}
        onClose={() => toggleMediaSettings(false)}
        testsEnabled={true}
      />
      <Panel>
        <div>
          <HeaderWrapper>
            <H1>{waitingRoomText}</H1>
            {!xs && (
              <AvailableWrapper>
                <AvailableToggle />
              </AvailableWrapper>
            )}
          </HeaderWrapper>
          <Content>
            <Column>
              <LocalVideoPreview
                showMediaControls={true}
                onMediaSettingsOpen={() =>
                  toggleMediaSettings(!changingMediaSettings)
                }
                isTherapist={true}
              />
              {xs && (
                <AvailableWrapper>
                  <AvailableToggle />
                </AvailableWrapper>
              )}
            </Column>
            <Column>
              <ClientList
                chatDisabled={disableChat}
                startDisabled={disableStart}
                startSession={startSession}
                loadingIdentity={loadingIdentity}
              />
            </Column>
          </Content>
        </div>
        <Footer setShowModal={setShowModal} />
      </Panel>
    </>
  );
};

export default TherapistVideoPanel;
