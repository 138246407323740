import React, { useEffect } from "react";
import styled from "styled-components";
import { Link, H3 } from "@sussex/react-kit/elements";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../hooks/useCopy";
import Panel from "../components/Panel";
import Header from "../components/Header";
import BackgroundImage from "../components/BackgroundImage";
import { datadogSetPage, datadogEvent } from "../datadog";

const Wrapper = styled.div`
  max-width: 875px;
  margin: 140px 15px 0;

  @media (min-width: 768px) {
    width: 75%;
    margin: 100px auto 0;
  }
`;

const Content = styled.div`
  margin: 10px 0;

  p {
    margin: 10px 0;
  }
`;

export default function ConnectionDroppedView({ isTherapist }) {
  const copyKeys = isTherapist
    ? [
        "disconnectedscreen.header_therapist",
        "disconnectedscreen.message_therapist",
        "disconnectedscreen.actionText_therapist",
      ]
    : [
        "disconnectedscreen.header",
        "disconnectedscreen.message",
        "disconnectedscreen.actionText",
      ];

  const [header, message, actionText] = useCopy(copyKeys);

  const variables = {
    ACTION_TEXT: (
      <Link
        onClick={() => {
          datadogEvent({
            category: "page",
            event: "reloaded",
            component: "ConnectionDroppedView",
          });

          window.location.reload();
        }}
      >
        {actionText}
      </Link>
    ),
  };

  useEffect(() => {
    datadogSetPage("ConnectionDropped");
  }, []);

  return (
    <>
      {!isTherapist && (
        <>
          <Header type="logoOnly" />
          <BackgroundImage />
        </>
      )}
      <Wrapper>
        <Panel>
          <Content>
            <H3>{header}</H3>
            <p>{swapCopyVariables(message, variables)}</p>
          </Content>
        </Panel>
      </Wrapper>
    </>
  );
}
