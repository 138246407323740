import React, { forwardRef } from "react";
import { useTheme } from "styled-components";

import MobileNavigation from "../../MobileNavigation";

import Desktop from "./Desktop";

const Subnav = forwardRef((props, ref) => {
  const {
    breakpoints: { desktop: desktopBreakpoint },
  } = useTheme();

  const query = window.matchMedia(`(min-width: ${desktopBreakpoint})`);
  if (query.matches) {
    return <Desktop ref={ref} {...props} />;
  }
  return <MobileNavigation {...props} />;
});

export default Subnav;
