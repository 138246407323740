import React from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import chatIcon from "../../assets/chat-icon-blue.svg";
import { useAppStateContext } from "../../providers/AppStateProvider";
import { datadogEvent } from "../../datadog";

const Wrapper = styled.button`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  appearance: none;
  outline: none;
  background: none;
  padding: 0;
  position: relative;
`;

const UnreadIcon = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  left: -2px;
  background: ${({ theme }) => theme.colors.red};
`;

const Icon = styled.img`
  width: 24px;
  height: 22px;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.colors.default};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const ChatButton = ({ onClick, unreadCount }) => {
  const [chatText] = useCopy(["sessioncontrols.chat"]);

  const {
    state: { waitingRoom },
  } = useAppStateContext();

  if (!waitingRoom.therapistAvailable) {
    return null;
  }

  const handleClick = () => {
    datadogEvent({
      category: "waiting_room_chat",
      feature: "panel",
      event: "opened",
      component: "InSessionWaitingRoom.ChatButton",
    });

    onClick();
  };

  return (
    <Wrapper onClick={handleClick}>
      <Icon src={chatIcon} alt={chatText} />
      {unreadCount > 0 && <UnreadIcon />}
      <Text>{chatText}</Text>
    </Wrapper>
  );
};

export default ChatButton;
