import React from "react";
import styled from "styled-components";
import { Tooltip } from "@material-ui/core";
import gearIcon from "../../assets/gear-icon.svg";
import useCopy from "../../hooks/useCopy";

const Wrapper = styled.button`
  background: ${({ theme }) => theme.colors.transparentGreyBackground};
  width: 30px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 0;
  outline: 0;
  cursor: pointer;
  &:hover,
  &:active {
    background: ${({ theme }) => theme.colors.transparentGreyBackgroundHover};
  }
`;

export default function SettingsButton({ onClick }) {
  const [settingsText] = useCopy(["navItem.settings"]);

  return (
    <Tooltip title={settingsText}>
      <Wrapper onClick={onClick}>
        <img src={gearIcon} alt={settingsText} />
      </Wrapper>
    </Tooltip>
  );
}
