import icon from "../assets/chair-icon.png";
import { datadogEvent } from "../datadog";

export const isNotificationSupported = () => {
  if (!window.Notification || !Notification.requestPermission) return false;
  if (Notification.permission === "granted") {
    return true;
  }
  try {
    new Notification("");
  } catch (e) {
    if (e.name === "TypeError") return false;
  }
  return true;
};

export const notify = ({ title, message, onClick }) => {
  // No browser support
  if (!isNotificationSupported()) {
    return;
  }

  if (Notification.permission === "granted") {
    let notified = true;

    try {
      const n = new Notification(title, {
        icon: icon,
        body: message,
      });
      n.onclick = e => {
        e.preventDefault();

        datadogEvent({
          category: "desktop_notifications",
          event: "clicked",
          component: "useDesktopNotification",
        });

        window.focus();
        onClick();
      };
      // If a browser can't do this :eyes: at Chrome on Android. Just give up.
    } catch (e) {
      notified = false;
    }

    if (notified) {
      datadogEvent({
        category: "desktop_notifications",
        event: "sent",
        component: "useDesktopNotification",
      });
    }
  }
};
