import React, { useEffect, createContext, useState } from "react";

export const CopyContext = createContext(null);

const defaultLanguage = "en-GB";
const defaultLanguages = {
  en: "en-GB",
  es: "es-ES",
  fr: "fr-FR",
};

export const getFallbackLanguage = lang => {
  const prefix = lang.split("-")[0];
  return defaultLanguages[prefix] || defaultLanguage;
};

export function CopyProvider({ lang, children }) {
  const [copy, setCopy] = useState({});
  useEffect(() => {
    import(`../../locale/${lang}`)
      .then(data => {
        setCopy(data);
      })
      .catch(e => {
        // Most likely error is a language we don't have copy for
        const fallbackLanguage = getFallbackLanguage(lang);
        import(`../../locale/${fallbackLanguage}`).then(data => {
          setCopy(data);
        });
      });
  }, [lang]);

  return <CopyContext.Provider value={copy}>{children}</CopyContext.Provider>;
}
