import { useRef, useEffect, useState } from "react";
import useVideoContext from "./useVideoContext";

export default function useRoomState() {
  const { room } = useVideoContext();
  const { localParticipant } = room;
  const [state, setState] = useState(
    localParticipant ? localParticipant.state : "disconnected",
  );
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!localParticipant) {
      return;
    }
    const setRoomState = () => {
      if (!isMounted.current) {
        return;
      }
      setState(localParticipant.state);
    };
    localParticipant
      .on("disconnected", setRoomState)
      .on("reconnected", setRoomState)
      .on("reconnecting", setRoomState);
    return () => {
      localParticipant
        .off("disconnected", setRoomState)
        .off("reconnected", setRoomState)
        .off("reconnecting", setRoomState);
    };
  }, [localParticipant]);

  return state;
}
