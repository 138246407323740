import React from "react";
import useConnectivityNotifications from "./hooks/useConnectivityNotifications";
import { Notification } from "../Notifications";

export default function ConnectivityNotifications() {
  useConnectivityNotifications();

  return (
    <Notification
      context="connectivity"
      autoHideDuration={10000}
      position="center"
    />
  );
}
