import React from "react";
import styled from "styled-components";

const Canvas = styled.canvas`
  display: none;
`;

function PhotoCanvas({ canvasRef }) {
  return <Canvas ref={canvasRef} />;
}

export default PhotoCanvas;
