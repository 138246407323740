import React, { useEffect } from "react";
import styled from "styled-components";

import Header from "../components/Header";
import ProfileImage from "../components/ProfileImage";
import { useWaitingRoomContext } from "../providers/WaitingRoomProvider";
import { WaitingRoomProvider } from "../providers/WaitingRoomProvider";
import WaitingRoomDetails from "../components/WaitingRoomDetails";
import useCopy from "../hooks/useCopy";
import BackgroundImage from "../components/BackgroundImage";
import Panel from "../components/Panel";
import { datadogSetPage, datadogSetUserType } from "../datadog";

const Body = styled.div`
  max-width: 875px;
  margin: 140px 15px 0;

  @media (min-width: 768px) {
    width: 75%;
    margin: 0 auto;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const ImageWrapper = styled.div`
  width: 75px;
`;

const Providers = ({ children }) => (
  <WaitingRoomProvider>
    <WaitingRoomDetails type="client">{children}</WaitingRoomDetails>
  </WaitingRoomProvider>
);

const MessageWrapper = styled.div`
  padding-left: 20px;

  h3 {
    font-size: 21px;
    margin: 0 0 3px;
    font-family: ${({ theme }) => theme.fonts.semiBold};
    @media (min-width: 768px) {
      font-size: 26px;
    }
  }

  p {
    margin: 0;
  }
`;

const View = () => {
  const { room } = useWaitingRoomContext();
  const [thankYouText, sessionTerminatedText] = useCopy([
    "exitscreen.thankyou",
    "exitscreen.sessionTerminated",
  ]);

  useEffect(() => {
    datadogSetUserType("client");
    datadogSetPage("SessionComplete");
  }, []);

  return (
    <div>
      <BackgroundImage />
      <Header type="logoOnly" />
      <Body>
        <Panel>
          <Content>
            <ImageWrapper>
              <ProfileImage
                src={room.therapistPhotoUrl}
                alt={room.therapistDisplayName}
              />
            </ImageWrapper>
            <MessageWrapper>
              <h3>{thankYouText}</h3>
              <p>{sessionTerminatedText}</p>
            </MessageWrapper>
          </Content>
        </Panel>
      </Body>
    </div>
  );
};
export default function ClientExitRoomView() {
  return (
    <Providers>
      <View />
    </Providers>
  );
}
