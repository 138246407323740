import React, { useState, useEffect, useRef } from "react";
import useVideoContext from "../hooks/useVideoContext";
import useRoomState from "../hooks/useRoomState";
import VideoSession from "../components/VideoSession";
import useTherapistMuted from "../hooks/useTherapistMuted";
import useSessionEnded from "../hooks/useSessionEnded";
import SessionStarting from "../components/SessionStarting";
import { useAudioAlertContext } from "../providers/AudioAlertProvider";
import { useTherapistKicked } from "../hooks/useTherapistKicked";
import { datadogSetPage } from "../datadog";

const states = {
  DISCONNECTED: -1,
  INITIALIZING: 0,
  CONNECTING: 1,
  CONNECTED: 2,
};

function Session({ isTherapist, playNotification, token, onDisconnect }) {
  const [loadScreenTimeoutExpired, setLoadScreenTimeoutExpired] = useState(
    false,
  );
  const [state, setState] = useState(states.INITIALIZING);
  const { connect } = useVideoContext();
  const roomState = useRoomState();
  const { playChime } = useAudioAlertContext();
  const isMounted = useRef(true);
  const [reconnecting, setReconnecting] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useTherapistMuted();
  useTherapistKicked();
  useSessionEnded(onDisconnect);

  // initial state, try to connect to video room
  useEffect(() => {
    if (state === states.INITIALIZING) {
      setState(states.CONNECTING);
      connect(token);

      if (playNotification) {
        playChime();
      }
    }
  }, [state, token, connect, playChime, playNotification]);

  // disconnection from the other participant, try to reconnect
  useEffect(() => {
    if (state !== states.DISCONNECTED) return;

    setTimeout(() => {
      if (isMounted.current) {
        setState(states.CONNECTING);
        connect(token);
      }
    });
  }, [state, token, connect]);

  // when we know the room is connected, update the state
  useEffect(() => {
    if (state === states.CONNECTED && roomState === "disconnected") {
      setState(states.DISCONNECTED);
      setReconnecting(true);
    }

    if (state === states.CONNECTING && roomState === "connected") {
      setState(states.CONNECTED);
    }
  }, [roomState, state]);

  useEffect(() => {
    datadogSetPage("Session");

    setTimeout(() => {
      setLoadScreenTimeoutExpired(true);
    }, 1500);
  }, []);

  // update the previous state value
  return state === states.CONNECTED && loadScreenTimeoutExpired ? (
    <VideoSession isTherapist={isTherapist} />
  ) : (
    <SessionStarting reconnecting={reconnecting} />
  );
}

export default Session;
