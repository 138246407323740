import React from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";

const Badge = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.light};
  text-align: center;
`;

export default function Reconnecting() {
  const [badgeText] = useCopy(["connectivity.badge"]);
  return <Badge>{badgeText}</Badge>;
}
