import React from "react";
import styled from "styled-components";
import { SubmitButton } from "@sussex/react-kit/elements";
import CopyRoomUrl from "./CopyRoomUrl";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.fonts.bold};
`;

export default function Footer({
  disabled,
  buttonText,
  copyLinkText,
  copiedToClipboardText,
  send,
}) {
  return (
    <Wrapper>
      <CopyRoomUrl
        copyLinkText={copyLinkText}
        copiedToClipboardText={copiedToClipboardText}
      />
      <SubmitButton disabled={disabled} onClick={send} retainWidthOnMobile>
        {buttonText}
      </SubmitButton>
    </Wrapper>
  );
}
