import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useAppStateContext } from "../../providers/AppStateProvider";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../../hooks/useCopy";
import { notify } from "../../hooks/useDesktopNotifications";
import useParticipantInfo from "../../hooks/useParticipantInfo";

const Notify = ({ participant }) => {
  const {
    state: { session },
  } = useAppStateContext();
  const history = useHistory();
  const [notificationTitle, notificationMessage] = useCopy([
    "desktopNotifications.clientJoinedTitle",
    "desktopNotifications.clientJoinedMessage",
  ]);
  const { displayName, status: participantInfoStatus } = useParticipantInfo(
    participant ? participant.participantId : "",
  );
  const notified = useRef([]);

  useEffect(() => {
    if (
      session.active ||
      !participant ||
      !displayName ||
      participantInfoStatus !== "success" ||
      notified.current.includes(participant.participantId)
    )
      return;

    notified.current.push(participant.participantId);

    const message = swapCopyVariables(notificationMessage, {
      NAME: displayName,
    }).join("");

    notify({
      title: notificationTitle,
      message: message,
      onClick: () => history.push("/dashboard"),
    });
  }, [
    session,
    participant,
    displayName,
    participantInfoStatus,
    history,
    notificationTitle,
    notificationMessage,
  ]);

  return null;
};

const DesktopNotification = () => {
  const [seenParticipants, setSeenParticipants] = useState([]);
  const [newParticipant, setNewParticipant] = useState(null);
  const {
    state: { waitingRoom, session },
  } = useAppStateContext();
  const { participants: waitingParticipants } = waitingRoom;

  // this ref tracks participants as "seen" when they show up to a room
  useEffect(() => {
    const newUnseenParticipants = waitingParticipants.filter(
      p =>
        !seenParticipants.map(s => s.participantId).includes(p.participantId),
    );

    if (newUnseenParticipants.length > 0) {
      if (session.active) {
        setSeenParticipants(prev => prev.concat(newUnseenParticipants));
      } else {
        setNewParticipant(newUnseenParticipants[0]);
        setSeenParticipants(prev => prev.concat(newUnseenParticipants));
      }
    }
  }, [waitingParticipants, seenParticipants, session.active]);

  return <Notify participant={newParticipant} />;
};

export default DesktopNotification;
