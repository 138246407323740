import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";

import EventBus from "../../eventBus";

import { useAppStateContext, actions } from "../../providers/AppStateProvider";
import { appInstanceId } from "../../App";
import Heartbeat from "react-heartbeat";
import messages from "./messages";

const {
  WEBSOCKET_SET_CONNECT,
  WEBSOCKET_SET_CONNECTED,
  WEBSOCKET_SET_ERROR,
  WAITROOM_SET_PARTICIPANTS,
  SESSION_START,
  WAITROOM_SET_THERAPIST_AVAILABLE,
} = actions;

const heartbeatInterval = 20000;
const getCurrentTime = () => new Date().getTime();
const toUnix = t => new Date(t).getTime() / 1000;
const normalize = a =>
  a.map(participant => ({
    participantId: participant.participant_id,
    participantType: participant.participant_type,
    connectedAt: participant.connected_at,
    showAsAvailable: participant.show_as_available,
  }));

const sort = a =>
  a.sort((a, b) => toUnix(a.connected_at) - toUnix(b.connected_at));

// the provider
export default function Websocket({ isTherapist }) {
  const { state, dispatch } = useAppStateContext();
  const [unexpectedClose, setUnexpectedClose] = useState(false);

  const lastPongDate = useRef(0);

  const reconnect = () => {
    setUnexpectedClose(true);
    setTimeout(() => {
      setUnexpectedClose(false);
    }, 1000);
  };

  const opts = useMemo(
    () => ({
      share: true,
      filter: event => {
        try {
          const parsed = JSON.parse(event.data);
          return !!parsed.message;
        } catch (err) {
          console.error(
            "Failed to parse websocket message",
            event && event.data,
            err,
          );
          return false;
        }
      },
      shouldReconnect: () => true,
      onError: e => {
        dispatch({ type: WEBSOCKET_SET_ERROR, error: e });
      },
      onOpen: () => {
        dispatch({ type: WEBSOCKET_SET_ERROR, error: null });
      },
    }),
    [dispatch],
  );

  const { readyState, lastJsonMessage, sendJsonMessage } = useWebSocket(
    process.env.REACT_APP_WS_URL + "?app_instance_id=" + appInstanceId,
    opts,
    state.websocket.connect === true && !unexpectedClose,
  );

  const heartbeatFunction = useCallback(() => {
    if (readyState === ReadyState.OPEN) {
      const lastPingDate = getCurrentTime();

      sendJsonMessage({
        message: messages.PING,
      });

      setTimeout(() => {
        if (lastPongDate.current < lastPingDate) {
          const err = Error("websocket not receiving messages");
          dispatch({ type: WEBSOCKET_SET_ERROR, error: err });
          reconnect();
        } else {
          dispatch({ type: WEBSOCKET_SET_ERROR, error: null });
        }
      }, heartbeatInterval / 4);
    }
  }, [readyState, sendJsonMessage, dispatch]);

  useEffect(() => {
    dispatch({
      type: WEBSOCKET_SET_CONNECTED,
      isConnected: readyState === ReadyState.OPEN,
    });

    // ask for list when websocket connects
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        message: messages.LIST,
      });
    }
  }, [dispatch, isTherapist, sendJsonMessage, readyState]);

  // Handle messages
  useEffect(() => {
    if (lastJsonMessage === null) {
      return;
    }

    if (lastJsonMessage.message === messages.CHAT) {
      const { message, from } = lastJsonMessage.payload;
      EventBus.emit("chat", {
        from: from,
        channel: from,
        message: message,
      });
      return;
    }

    if (lastJsonMessage.message === messages.PONG) {
      lastPongDate.current = getCurrentTime();
      return;
    }

    if (lastJsonMessage.message === messages.SESSION_READY) {
      const serverTime =
        parseInt(lastJsonMessage.payload.current_time, 10) * 1000;
      const localDrift = Math.round((new Date() - serverTime) / 1000);
      dispatch({
        type: SESSION_START,
        token: lastJsonMessage.payload.token,
        startTime: lastJsonMessage.payload.created_at + localDrift,
      });
    }

    if (lastJsonMessage.message === messages.PARTICIPANT_LIST_CHANGED) {
      dispatch({
        type: WAITROOM_SET_PARTICIPANTS,
        participants: normalize(sort(lastJsonMessage.payload)),
      });
      if (!isTherapist) {
        // We only care about the therapist availability
        const therapist = lastJsonMessage.payload.find(
          x => x.participant_type === "therapist",
        );
        if (therapist) {
          dispatch({
            type: WAITROOM_SET_THERAPIST_AVAILABLE,
            available: therapist.show_as_available,
            isInitialState: true,
          });
        }
      }
    }

    if (lastJsonMessage.message === messages.THERAPIST_AVAILABLE) {
      dispatch({
        type: WAITROOM_SET_THERAPIST_AVAILABLE,
        available: lastJsonMessage.payload,
        isInitialState: false,
      });
    }
  }, [dispatch, lastJsonMessage, sendJsonMessage, isTherapist]);

  useEffect(() => {
    return () => {
      dispatch({
        type: WEBSOCKET_SET_CONNECT,
        connect: false,
      });
    };
  }, [dispatch]);

  return (
    <Heartbeat
      heartbeatFunction={heartbeatFunction}
      heartbeatInterval={heartbeatInterval}
    />
  );
}
