// The default background
export const DefaultBackground = "sitting-room";

// The list of available backgrounds
const Backgrounds = {
  "sitting-room": "sitting-room.jpg",
  mountains: "mountains.jpg",
  "sitting-room-2": "sitting-room-2.jpg",
  flowers: "flowers.jpg",
  beach: "beach.jpg",
  yellow: "yellow.jpg",
  waterfall: "waterfall.jpg",
  "sitting-room-3": "sitting-room-3.jpg",
  confetti: "confetti.jpg",
  "white-flower": "white-flower.jpg",
  tiles: "tiles.jpg",
  lake: "lake.jpg",
  eye: "eye.jpg",
  "mountains-2": "mountains-2.jpg",
  paint: "paint.jpg",
  sunset: "sunset.jpg",
};

export default Backgrounds;
