import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import IconButton from "./IconButton";
import theme from "../../../theme";
import useLocalVideoToggle from "../../../hooks/useLocalVideoToggle";
import enabledIcon from "../../../assets/active-camera-icon.svg";
import disabledIcon from "../../../assets/inactive-camera-icon.svg";
import useCopy from "../../../hooks/useCopy";
import { datadogEvent } from "../../../datadog";

const EnabledIcon = styled.img`
  width: 43%;
`;

const DisabledIcon = styled.img`
  width: 69%;
  margin-top: 1.7%;
  margin-left: -1%;
`;

export default function VideoToggleButton() {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const [disableVideoText, enableVideoText] = useCopy([
    "sessioncontrols.disableVideo",
    "sessioncontrols.enableVideo",
  ]);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const onClick = () => {
    if (isVideoEnabled) {
      setButtonDisabled(true);
      setTimeout(() => isMounted.current && setButtonDisabled(false), 1000);
    }

    datadogEvent({
      category: "controls",
      feature: "camera",
      event: isVideoEnabled ? "disabled" : "enabled",
      component: "SessionControls.Buttons.VideoToggleButton",
    });

    toggleVideoEnabled();
  };

  return (
    <IconButton
      onClick={onClick}
      id="toggleVideo"
      background={!isVideoEnabled ? theme.colors.red : undefined}
      tooltip={isVideoEnabled ? disableVideoText : enableVideoText}
      disabled={buttonDisabled}
    >
      {isVideoEnabled ? (
        <EnabledIcon src={enabledIcon} alt={disableVideoText} />
      ) : (
        <DisabledIcon src={disabledIcon} alt={enableVideoText} />
      )}
    </IconButton>
  );
}
