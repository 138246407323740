import { useCallback, useEffect } from "react";
import { isdef, isUndefined } from "@sussex/react-kit/utils";
import { useAppStateContext } from "../../../providers/AppStateProvider";

export default function useDataTrack(bus, room, dataTrack) {
  const { state } = useAppStateContext();
  useEffect(() => {
    const participantInfo = state.participantInfo;
    const onMessage = (data, track, participant) => {
      const info = participantInfo[participant.identity];

      const ALLOWED_MESSAGES = [
        { type: "chat", therapistOnly: false },
        { type: "mute", therapistOnly: true },
        { type: "unmute", therapistOnly: true },
        { type: "kick", therapistOnly: true },
      ];

      let message = null;

      try {
        message = JSON.parse(data);
      } catch (err) {
        console.error("Error parsing data track message", data);
      }

      if (message) {
        if (
          isdef(message.to) &&
          message.to !== room.localParticipant.identity
        ) {
          return;
        }

        const perms = ALLOWED_MESSAGES.find(m => m.type === message.type);
        if (isUndefined(perms)) {
          return;
        }

        if (
          !perms.therapistOnly ||
          (perms.therapistOnly && info.participantType === "therapist")
        ) {
          bus.emit(message.type, {
            participant: participant,
            message: message,
          });
        }
      }
    };

    room.on("trackMessage", onMessage);

    return () => {
      room.off("trackMessage", onMessage);
    };
  }, [bus, room, state.participantInfo]);

  const sendMessage = useCallback(
    message => {
      dataTrack.send(JSON.stringify(message));
    },
    [dataTrack],
  );

  return { sendMessage };
}
