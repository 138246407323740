import React from "react";
import useVideoContext from "../../hooks/useVideoContext";
import styled from "styled-components";
import Participant from "../Participant";
import ParticipantInfo from "../ParticipantInfo";
import useMainSpeaker from "../../hooks/useMainSpeaker";

const Wrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  box-shadow: none;
  width: 75px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.dark};
  & video {
    object-fit: cover;
    height: 100%;
  }

  @media (min-width: 768px) {
    top: 0px;
    right: 0px;
    width: 250px;
    overflow-x: hidden;
    overflow-y: auto;
    border: 5px solid white;
    box-shadow: 2px 2px 10px 0px ${({ theme }) => theme.colors.greyOverlay};
    border-radius: 0 0 0 3px;

    & video {
      object-fit: contain;
      height: 100%;
    }
  }
`;

const ParticipantWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const AspectRatio = styled.div`
  position: relative;
  padding-top: 140%;

  @media (min-width: 768px) {
    padding-top: 56%;
  }
`;

const SecondaryParticipants = ({ isTherapist, visible }) => {
  const mainParticipant = useMainSpeaker();
  const {
    room: { localParticipant },
    participants: allParticipants,
    screenShareParticipant,
    selectedParticipant,
  } = useVideoContext();

  const participants = [...allParticipants, localParticipant].filter(p => {
    // If there is a screen share participant, but not presenter, display all participants
    if (screenShareParticipant && screenShareParticipant !== localParticipant)
      return true;
    // If there's no pinned participant, display local, unless local is only participant
    if (p === localParticipant && p !== mainParticipant) return true;
    // If there's a pinned participant, figure out the non-pinned participants
    return selectedParticipant && p !== mainParticipant;
  });

  if (participants.length === 0) {
    return null;
  }

  if (!visible) {
    return (
      <>
        {participants.map((p, i) => (
          <Participant participant={p} disableVideo={true} />
        ))}
      </>
    );
  }

  return (
    <Wrapper>
      {participants.map((p, i) => (
        <AspectRatio key={i}>
          <ParticipantWrap>
            <Participant
              participant={p}
              showAdminControls={isTherapist && p !== localParticipant}
            >
              <ParticipantInfo participant={p} thumbnail />
            </Participant>
          </ParticipantWrap>
        </AspectRatio>
      ))}
    </Wrapper>
  );
};

export default SecondaryParticipants;
