import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import Menu from "./Menu";
import Header from "../Header";
import BackgroundImage from "../BackgroundImage";
import useWindowSize from "../../hooks/useWindowSize";

const Main = styled.div`
  display: flex;
`;

const Sidebar = styled.div`
  display: none;
  flex: 0 0 auto;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  padding-top: 120px;
  width: 200px;
  background: linear-gradient(
    90deg,
    rgba(44, 45, 48, 50%),
    rgba(196, 196, 196, 0)
  );
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: flex;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* Add left margin equal to the sidbar content so that it appears
  centered in the remaining space. */
  ${({ offset }) =>
    offset &&
    css`
      margin-left: ${offset}px;
    `}
`;

const BodyWrapper = styled.div`
  margin: 0 auto 30px;
  width: calc(100% - 30px);
  @media (min-width: 768px) {
    width: 500px;
    margin-bottom: 70px;
  }
  @media (min-width: 960px) {
    width: 750px;
  }
  @media (min-width: 1200px) {
    width: 875px;
    ${({ isTherapist }) =>
      isTherapist &&
      css`
        width: calc(90% - 200px);
        max-width: 1200px;
      `}
  }
`;

const SidebarLayout = ({ isTherapist, children }) => {
  const location = useLocation();
  const menuRef = useRef(null);
  const [sidebarWidth, setSidebarWidth] = useState(0);
  const { width } = useWindowSize();

  useEffect(() => {
    if (!menuRef.current) return;
    setSidebarWidth(menuRef.current.offsetWidth);
  }, [width]);

  return (
    <>
      <BackgroundImage />
      <Header
        type={isTherapist ? "therapist" : "client"}
        activePage={location.pathname}
      />
      <Main>
        <Sidebar>
          <div ref={menuRef}>
            <Menu activePage={location.pathname} />
          </div>
        </Sidebar>
        <Content offset={sidebarWidth}>
          <BodyWrapper isTherapist={isTherapist}>{children}</BodyWrapper>
        </Content>
      </Main>
    </>
  );
};

export default SidebarLayout;
