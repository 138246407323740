import React, { createContext, useReducer, useCallback } from "react";

export const NotificationContext = createContext({});

const ADD_MESSAGE = "ADD_MESSAGE";
const MESSAGE_CLOSED = "MESSAGE_CLOSED";
const defaultContext = "default";

const addMessage = (state, action) => {
  const messageIndex = state.messageIndex + 1;
  const newMessage = {
    message: action.message,
    id: messageIndex,
    context: action.context,
  };
  const unreadMessages = state.unreadMessages
    .filter(m => m.context === action.context)
    .concat(newMessage);
  const newState = {
    ...state,
    messageIndex,
    unreadMessages,
  };
  // Advance to first message if no current message
  if (state.currentMessages.some(m => m && m.context === action.context)) {
    return newState;
  }
  return nextMessage(newState, action);
};

const nextMessage = (state, action) => {
  const nextMessageForContext = state.unreadMessages.find(
    m => m.context === action.context,
  );
  const currentMessages = state.currentMessages.filter(
    m => m.context !== action.context,
  );
  if (nextMessageForContext) {
    currentMessages.push(nextMessageForContext);
  }
  const unreadMessages = state.unreadMessages.filter(
    a => a.id !== nextMessageForContext.id,
  );
  return {
    ...state,
    currentMessages,
    unreadMessages,
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return addMessage(state, action);
    case MESSAGE_CLOSED:
      return nextMessage(state, action);
    default:
      return state;
  }
};

const initialState = {
  messageIndex: 0,
  currentMessages: [],
  unreadMessages: [],
};

export default function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentMessages } = state;

  const addMessage = useCallback(
    (message, context = defaultContext) => {
      dispatch({ type: ADD_MESSAGE, message, context });
    },
    [dispatch],
  );

  const closeMessage = useCallback(
    (context = defaultContext) => {
      dispatch({ type: MESSAGE_CLOSED, context });
    },
    [dispatch],
  );

  return (
    <NotificationContext.Provider
      value={{ currentMessages, addMessage, closeMessage }}
    >
      {children}
    </NotificationContext.Provider>
  );
}
