import React, { useContext, createContext, useState } from "react";

export const InSessionWaitingRoomContext = createContext(null);

export function InSessionWaitingRoomProvider({ children }) {
  const [notification, setNotification] = useState(false);

  return (
    <InSessionWaitingRoomContext.Provider
      value={{
        notification,
        setNotification,
      }}
    >
      {children}
    </InSessionWaitingRoomContext.Provider>
  );
}

export function useInSessionWaitingRoomContext() {
  const context = useContext(InSessionWaitingRoomContext);
  if (!context) {
    throw new Error(
      "useWaitingRoomContext must be used within an InSessionWaitingRoomProvider",
    );
  }

  return context;
}
