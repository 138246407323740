import React from "react";
import styled, { css } from "styled-components";
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  padding: 20px 15px;
  font-size: ${({ theme }) => theme.fontSize.normal};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  align-items: center;
  line-height: 1;
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      font-family: ${({ theme }) => theme.fonts.semiBold};
    `}

  @media(min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    color: white;
    margin: 0 0 30px 15px;
    padding: 0;
    font-size: 16px;
    border-bottom: 0;
    ${({ active }) =>
      active &&
      css`
        font-family: ${({ theme }) => theme.fonts.bold};
        color: white;
      `}
  }
`;

export default function NavItem({ active, text, path, onClick }) {
  const history = useHistory();
  const handleClick = () => {
    if (!active && path) {
      history.push(path);
    }
    if (typeof onClick !== "undefined") {
      return onClick();
    }
  };
  return (
    <Wrapper onClick={handleClick} active={active}>
      <span>{text}</span>
    </Wrapper>
  );
}
