import React, { useState, useEffect } from "react";
import TherapistVideoPanel from "../TherapistVideoPanel";
import Notification from "./Notification";
import ConnectError from "./ConnectError";
import { datadogSetPage } from "../../datadog";
import { useAppStateContext } from "../../providers/AppStateProvider";

export default function Dashboard({ message, connectFailed }) {
  const {
    state: { websocket },
  } = useAppStateContext();
  const [startSessionFailed, setStartSessionFailed] = useState(false);
  let connectError = "";
  if (connectFailed) {
    connectError = "connect";
  } else if (startSessionFailed) {
    connectError = "start_session";
  } else if (websocket.error) {
    connectError = "websocket";
  }

  useEffect(() => {
    datadogSetPage("Dashboard");
  }, []);

  return (
    <>
      {connectError && <ConnectError reason={connectError} />}
      <Notification message={message} />
      <TherapistVideoPanel setStartSessionFailed={setStartSessionFailed} />
    </>
  );
}
