import { H2 } from "@sussex/react-kit/elements";
import React from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import { useAppStateContext } from "../../providers/AppStateProvider";

const Wrapper = styled.div`
  padding-top: 30px;
  h2 {
    margin-bottom: 10px;
    font-size: 26px;
  }
  @media (min-width: 768px) {
    padding-top: 0;
  }
`;
const TextWrapper = styled.div`
  margin-bottom: 30px;
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 24px;
  padding-right: 30px;
`;

function CheckedInView() {
  const { state } = useAppStateContext();
  const [thanksText, holdTightText] = useCopy([
    "checkinscreen.thanks",
    "checkinscreen.holdTight",
  ]);

  return state.websocket.isConnected ? (
    <Wrapper>
      <H2>{thanksText}</H2>
      <TextWrapper>{holdTightText}</TextWrapper>
    </Wrapper>
  ) : null;
}

export default CheckedInView;
