import { useState, useEffect, useContext } from "react";
import useRoomState from "../../../hooks/useRoomState";
import { NotificationContext } from "../../Notifications";
import useCopy from "../../../hooks/useCopy";

const connectivityContext = "connectivity";

export default function useConnectivityNotifications() {
  const [lastState, setLastState] = useState(null);
  const roomState = useRoomState();
  const { addMessage } = useContext(NotificationContext);
  const [reconnectedText] = useCopy(["notifications.reconnected"]);

  useEffect(() => {
    if (lastState === roomState) {
      return;
    }
    if (lastState === "reconnecting" && roomState === "connected") {
      addMessage(reconnectedText, connectivityContext);
    }
    setLastState(roomState);
  }, [addMessage, roomState, lastState, reconnectedText]);
}
