import React from "react";
import styled from "styled-components";
import { useChatContext } from "../Chat";
import { H3 } from "@sussex/react-kit/elements";
import ClientPicture from "../ClientPicture";
import AddButton from "../Chat/AddButton";
import ChatButton from "./ChatButton";
import useParticipantInfo from "../../hooks/useParticipantInfo";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
`;

const PictureWrapper = styled.div`
  width: 50px;
  flex: 0;
`;

const NameWrapper = styled.div`
  flex: 1;
  padding: 0 10px;
  overflow: hidden;

  & > * {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const Name = styled(H3)`
  color: ${({ theme }) => theme.colors.default};
`;

const LastChatMessage = styled.p`
  display: block;
  margin: 0;
  color: ${({ theme }) => theme.colors.secondary};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 1.4;
`;

const ButtonWrapper = styled.div`
  flex: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  & > * {
    margin: 0 15px 0 0;
  }
  & > *:last-of-type {
    margin: 0;
  }
`;

export default function ClientItem({
  participant = {},
  onAdd,
  addDisabled,
  onChat,
  chatDisabled,
  loading,
}) {
  const { getMessages, getUnreadCount } = useChatContext();
  const { displayName, photoUrl } = useParticipantInfo(
    participant.participantId,
  );
  const chatLog = getMessages(participant.participantId);
  const unreadCount = getUnreadCount(participant.participantId);
  const lastChat = chatLog.length > 0 && chatLog[chatLog.length - 1];

  return (
    <Wrapper>
      <PictureWrapper>
        <ClientPicture photo={photoUrl} disabled={addDisabled} />
      </PictureWrapper>
      <NameWrapper>
        <Name>{displayName}</Name>
        {lastChat && <LastChatMessage>{lastChat.message}</LastChatMessage>}
      </NameWrapper>
      <ButtonWrapper>
        <AddButton
          onClick={onAdd}
          disabled={loading || addDisabled}
          inSession={true}
        />
        <ChatButton
          onClick={onChat}
          disabled={chatDisabled}
          unreadCount={unreadCount}
        />
      </ButtonWrapper>
    </Wrapper>
  );
}
