import React from "react";
import styled from "styled-components";
import Panel from "../Panel";
import successImg from "../../assets/invite-sent-success.svg";
import failImg from "../../assets/fail.svg";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../../hooks/useCopy";

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Content = styled.div`
  margin-left: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  > * {
    margin-right: 10px;
  }
`;

const InviteSentNotification = ({ message, name }) => {
  const [successAlt, failAlt, successText, failText] = useCopy([
    "invite.successAlt",
    "invite.failAlt",
    "invite.successMessage",
    "invite.failMessage",
  ]);
  if (message == null) {
    return null;
  }
  return (
    <Wrapper>
      <Panel>
        <ContentWrapper>
          <Content>
            <img
              src={message === "success" ? successImg : failImg}
              alt={message === "success" ? successAlt : failAlt}
            />
            <div>
              {message === "success"
                ? swapCopyVariables(successText, { NAME: name })
                : failText}
            </div>
          </Content>
        </ContentWrapper>
      </Panel>
    </Wrapper>
  );
};

export default InviteSentNotification;
