import React from "react";
import styled, { css } from "styled-components";
import { Button } from "@sussex/react-kit/elements";
import arrow from "../../assets/arrow-down.svg";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../../hooks/useCopy";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ButtonContainer = styled.div`
  @media (max-width: 767px) {
    button {
      min-width: 100px;
    }
  }
`;

const CurrentPage = styled.div`
  white-space: nowrap;
  margin: 0 20px;
`;

const Arrow = styled.img`
  font-size: 20px;
  transform-origin: center top;
  margin-bottom: -3px;

  ${({ direction }) =>
    direction === "left" &&
    css`
      transform: rotate(90deg);
      margin-right: 5px;
    `}

  ${({ direction }) =>
    direction === "right" &&
    css`
      transform: rotate(-90deg);
      margin-left: 5px;
    `}
`;

const Pagination = ({ page, totalPages, onNext, onPrevious, loading }) => {
  const [nextText, prevText, currentPageText] = useCopy([
    "pagination.next",
    "pagination.previous",
    "pagination.currentPage",
  ]);
  return (
    <Wrapper>
      <ButtonContainer>
        <Button
          variant="white"
          onClick={onPrevious}
          disabled={loading || page <= 1}
        >
          <Arrow direction="left" src={arrow} />
          {prevText}
        </Button>
      </ButtonContainer>
      <CurrentPage>
        {swapCopyVariables(currentPageText, {
          PAGE: page,
          TOTAL_PAGES: totalPages,
        })}
      </CurrentPage>
      <ButtonContainer>
        <Button
          variant="white"
          onClick={onNext}
          disabled={page === totalPages || loading}
        >
          {nextText}
          <Arrow direction="right" src={arrow} />
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
};

export default Pagination;
