import React from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";

import loadingIcon from "../../assets/loading.svg";

const Wrapper = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.default};
`;

const Inner = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  background: white;
  max-height: 100px;
  max-width: 250px;
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const Text = styled.span`
  display: flex;
  height: 100px;
  width: 250px;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const SessionStarting = ({ reconnecting }) => {
  const [message] = useCopy([
    reconnecting
      ? "dashboard.reconnectingMessage"
      : "dashboard.startingMessage",
  ]);

  return (
    <Wrapper>
      <Inner>
        <Text>
          <Icon src={loadingIcon} alt="loading" />
          {message}
        </Text>
      </Inner>
    </Wrapper>
  );
};

export default SessionStarting;
