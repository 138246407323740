import React from "react";
import useTrack from "../../hooks/useTrack";
import AudioTrack from "../AudioTrack/";
import VideoTrack from "../VideoTrack";

export default function Publication({
  publication,
  isLocal,
  isMain,
  disableAudio,
  disableVideo,
  videoPriority,
  setVideo,
}) {
  const track = useTrack(publication);

  if (!track || track.isStopped) {
    return null;
  }

  switch (track.kind) {
    case "video":
      return disableVideo ? null : (
        <VideoTrack
          track={track}
          priority={videoPriority}
          isLocal={track.name.includes("camera") && isLocal}
          isScreenShare={track.name.includes("screen")}
          setVideo={setVideo}
          isMain={isMain}
        />
      );
    case "audio":
      return disableAudio ? null : <AudioTrack track={track} />;
    default:
      return null;
  }
}
