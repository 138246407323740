import { useState, useCallback, useEffect } from "react";
import { datadogEvent } from "../../../datadog";

export default function useScreenShareToggle(room, onError) {
  const [screenSharePublication, setScreenSharePublication] = useState(null);

  const stopScreenShare = useCallback(() => {
    const publication =
      screenSharePublication ||
      Array.from(room.localParticipant.tracks.values()).find(track =>
        track.trackName.includes("screen"),
      );
    if (publication) {
      // TODO: remove this if the SDK is updated to emit this event
      room.localParticipant.emit("trackUnpublished", publication);
      room.localParticipant.unpublishTrack(publication.track);
      publication.track && publication.track.stop();
      setScreenSharePublication(null);

      datadogEvent({
        category: "session",
        feature: "screenshare",
        event: "ended",
        component: "VideoProvider.useScreenShareToggle",
      });
    }
  }, [room, screenSharePublication]);

  const startScreenShare = useCallback(() => {
    navigator.mediaDevices
      .getDisplayMedia({
        audio: false,
        // use twilio recommended hard coded values
        video: {
          frameRate: 10,
        },
      })
      .then(stream => {
        const track = stream.getTracks()[0];
        // All video tracks are published with 'low' priority. This works because the video
        // track that is displayed in the 'MainParticipant' component will have it's priority
        // set to 'high' via track.setPriority()
        room.localParticipant
          .publishTrack(track, {
            name: "screen", // Tracks can be named to easily find them later
            priority: "high",
          })
          .then(trackPublication => {
            setScreenSharePublication(trackPublication);
            track.onended = stopScreenShare;

            datadogEvent({
              category: "session",
              feature: "screenshare",
              event: "started",
              component: "VideoProvider.useScreenShareToggle",
            });
          })
          .catch(onError);
      })
      .catch(error => {
        // Don't display an error if the user closes the screen share dialog
        if (error.name !== "AbortError" && error.name !== "NotAllowedError") {
          onError(error);
        }
      });
  }, [room, onError, stopScreenShare]);

  const toggleScreenShare = useCallback(() => {
    screenSharePublication && screenSharePublication.isTrackEnabled
      ? stopScreenShare()
      : startScreenShare();
  }, [stopScreenShare, startScreenShare, screenSharePublication]);

  useEffect(() => {
    const handler = () => {
      if (screenSharePublication && screenSharePublication.isTrackEnabled) {
        stopScreenShare();
      }
    };

    room.on("disconnected", handler);
    return () => {
      room.off("disconnected", handler);
    };
  }, [room, stopScreenShare, screenSharePublication]);

  return {
    toggleScreenShare,
    isScreenSharing:
      screenSharePublication && screenSharePublication.isTrackEnabled,
  };
}
