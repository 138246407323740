const messages = {
  SESSION_READY: "sessionReady",
  PARTICIPANT_LIST_CHANGED: "participantList",
  CHAT: "chat",
  THERAPIST_AVAILABLE: "therapistAvailability",
  PING: "ping",
  PONG: "pong",
  LIST: "list",
};

export default messages;
