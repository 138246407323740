import React, { useState } from "react";
import styled from "styled-components";
import Navigation from "./Navigation";
import AccountMenu from "./AccountMenu";
import TherapistInfo from "./TherapistInfo";
import ptLogo from "../../assets/logo.svg";
import sessionsLogo from "../../assets/pt-sessions-logo-lg.svg";
import NavMenu from "../NavMenu";

const Wrapper = styled.header`
  height: auto;
  background: linear-gradient(
    180deg,
    rgba(44, 45, 48, 0.75) 0%,
    rgba(196, 196, 196, 0) 100%
  );
  box-sizing: border-box;
  z-index: 1;
  @media (min-width: 768px) {
    height: 100px;
    position: sticky;
    top: 0;
    margin-bottom: 20px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    pointer-events: none;
    & button,
    & a {
      pointer-events: auto;
    }
  }
`;

const ContentWrapper = styled.div`
  width: calc(100% - 30px);
  padding: 0 15px 25px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    flex-direction: row;
  }
`;

const Logo = styled.img`
  display: block;
  user-select: none;
`;

const PTLogo = styled(Logo)`
  height: 30px;
  padding: 15px 0;
`;

const SessionsLogo = styled(Logo)`
  height: 42px;
`;

const MobileMenu = styled.div`
  display: block;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  height: 100%;
  width: 80%;
  z-index: 10;
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25);
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: none;
  }
`;

const ContentOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(84, 94, 110, 0.4);
  z-index: 9;
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: none;
  }
`;

function Header({ type, activePage }) {
  const [mobileMenuEnabled, setMobileMenuEnabled] = useState(false);

  const getHeaderContent = () => {
    switch (type) {
      case "therapist":
        return (
          <>
            {mobileMenuEnabled && (
              <>
                <ContentOverlay />
                <MobileMenu>
                  <NavMenu onClose={() => setMobileMenuEnabled(false)} />
                </MobileMenu>
              </>
            )}
            <PTLogo src={ptLogo} />
            <Navigation />
            <AccountMenu />
          </>
        );
      case "client":
        return <TherapistInfo />;
      case "logoOnly":
        return <SessionsLogo src={sessionsLogo} />;
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      <ContentWrapper>{getHeaderContent()}</ContentWrapper>
    </Wrapper>
  );
}

export default Header;
