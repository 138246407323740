export const getLocalAudioTrack = tracks =>
  tracks.find(t => t.kind === "audio");

export const getLocalVideoTrack = tracks =>
  tracks.find(t => t.kind === "video");

export const getLoginUrl = redirectUrl => {
  if (!redirectUrl) {
    redirectUrl = window.location.origin + "/dashboard";
  }
  return (
    `${process.env.REACT_APP_MEMBER_WEB}?url=` + encodeURIComponent(redirectUrl)
  );
};

export const sanitizeRoomAlias = roomAlias => {
  // replace whitespace with hyphens
  const val = roomAlias.replace(/\s/g, "-");

  // remove characters that require uri encoding
  return val
    .split("")
    .filter(
      (char, index) => window.encodeURIComponent(char) === char && index < 100,
    )
    .join("");
};
