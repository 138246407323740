import React, { useState } from "react";
import styled from "styled-components";
import useCopy from "../../../hooks/useCopy";
import {
  testAudioInputDevice,
  testAudioOutputDevice,
  AudioInputTest,
  AudioOutputTest,
} from "@twilio/rtc-diagnostics";
import successImg from "../../../assets/success.svg";
import failImg from "../../../assets/fail-2.svg";
import { datadogEvent } from "../../../datadog";

export const Action = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme }) => theme.colors.primaryBlue};
  text-decoration: none;
  cursor: pointer;
  margin-left: 5px;
`;

const Text = styled.label`
  color: ${({ theme }) => theme.colors.gray};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.normal};
  margin-left: 5px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TEST_STATUS_INITIAL = "initial";
const TEST_STATUS_TESTING = "testing";
const TEST_STATUS_PASSED = "passed";
const TEST_STATUS_FAILED = "failed";

export default function TestDevice({ device }) {
  const [testStatus, setTestStatus] = useState(TEST_STATUS_INITIAL);
  const [testText, testingText, passedText, failedText, retryText] = useCopy([
    "testDevice.test",
    "testDevice.testing",
    "testDevice.passed",
    "testDevice.failed",
    "testDevice.retry",
  ]);

  const testDevice = () => {
    let testerFn = null;
    let endEvent = null;
    let featureName = "";

    if (device.kind === "audioinput") {
      testerFn = testAudioInputDevice;
      endEvent = AudioInputTest.Events.End;
      featureName = "audio_input_test";
    } else if (device.kind === "audiooutput") {
      testerFn = testAudioOutputDevice;
      endEvent = AudioOutputTest.Events.End;
      featureName = "audio_output_test";
    }

    if (testerFn) {
      datadogEvent({
        category: "media_settings",
        feature: featureName,
        event: "clicked",
        component: "SessionControls.MediaSelection.TestDevice",
        metadata: {
          device: device.label || "default",
        },
      });

      setTestStatus(TEST_STATUS_TESTING);

      const opts = {};
      if (device.deviceId) {
        opts.deviceId = device.deviceId;
      }

      const deviceTest = testerFn(opts);

      deviceTest.on(endEvent, report => {
        const working =
          report.errors.length === 0 && !!report.values.find(v => v > 0);

        if (working) {
          setTestStatus(TEST_STATUS_PASSED);

          setTimeout(() => {
            setTestStatus(TEST_STATUS_INITIAL);
          }, 3000);
        } else {
          setTestStatus(TEST_STATUS_FAILED);
        }

        datadogEvent({
          category: "media_settings",
          feature: featureName,
          event: working ? "passed" : "failed",
          component: "SessionControls.MediaSelection.TestDevice",
          metadata: {
            device: device.label || "default",
          },
        });
      });

      setTimeout(() => {
        deviceTest.stop();
      }, 5000);
    }
  };

  let label = null;

  if (testStatus === TEST_STATUS_TESTING) {
    label = <Text>{testingText}</Text>;
  } else if (testStatus === TEST_STATUS_PASSED) {
    label = (
      <Wrapper>
        <img src={successImg} alt="" width="18" height="18" />
        <Text>{passedText}</Text>
      </Wrapper>
    );
  } else if (testStatus === TEST_STATUS_FAILED) {
    label = (
      <Wrapper>
        <img src={failImg} alt="" width="18" height="18" />
        <Text>{failedText}&nbsp;-</Text>
        <Action onClick={testDevice}>{retryText}</Action>
      </Wrapper>
    );
  } else {
    label = <Action onClick={testDevice}>{testText}</Action>;
  }

  return label;
}
