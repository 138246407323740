import React from "react";
import styled from "styled-components";
import IconButton from "./IconButton";
import Counter from "./Counter";
import icon from "../../../assets/chat-icon.svg";
import useCopy from "../../../hooks/useCopy";
import { useChatContext } from "../../Chat";
import { datadogEvent } from "../../../datadog";

const Icon = styled.img`
  width: 43%;
`;

export default function ChatButton({ channel, inSession = true }) {
  const { getUnreadCount, activeChannel, setActiveChannel } = useChatContext();

  const [chatText] = useCopy(["sessioncontrols.chat"]);

  const handleClick = () => {
    const chan = activeChannel === channel ? "" : channel;
    setActiveChannel(chan);

    datadogEvent({
      category: inSession ? "session_chat" : "waiting_room_chat",
      feature: "panel",
      event: chan ? "opened" : "closed",
      component: "SessionControls.Buttons.ChatButton",
    });
  };

  const unreadCount = getUnreadCount(channel);

  return (
    <IconButton onClick={handleClick} tooltip={chatText} id="chatWindow">
      <Icon src={icon} alt={chatText} />
      {unreadCount > 0 && <Counter id="unreadMessages">{unreadCount}</Counter>}
    </IconButton>
  );
}
