import React from "react";
import useNotifyWaitingRoomUsers from "./hooks/useNotifyWaitingRoomUsers";
import useNotifyMutedUsers from "./hooks/useNotifyMutedUsers";
import { Notification } from "../Notifications";

export default function InSessionNotifications({ isTherapist }) {
  useNotifyWaitingRoomUsers(isTherapist);
  useNotifyMutedUsers();

  return <Notification />;
}
