import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import App from "./App";
import ClientRoomView from "./pages/ClientRoomView";
import ClientExitRoomView from "./pages/ClientExitRoomView";
import Admin from "./pages/Admin";
import LandingView from "./pages/LandingView";
import NotFoundView from "./pages/NotFoundView";

function AppRouter() {
  return (
    <Router>
      <App>
        <Switch>
          <Route path="/dashboard">
            <Admin />
          </Route>
          <Route exact path="/">
            <LandingView />
          </Route>
          <Route exact path="/:roomAlias">
            <ClientRoomView />
          </Route>
          <Route exact path="/:roomAlias/complete">
            <ClientExitRoomView />
          </Route>
          <Route>
            <NotFoundView />
          </Route>
        </Switch>
      </App>
    </Router>
  );
}

export default AppRouter;
