import React from "react";
import ReactIs from "react-is";
import styled from "styled-components";

const Box = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  background: white;
  font-size: 17px;
  line-height: 22px;

  &:first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &:last-of-type {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom: 0;
  }
`;

const Wrapper = styled.div`
  box-shadow: 0px 0px 20px rgba(52, 63, 83, 0.3);
`;

export default function Panel({ className, children }) {
  const wrapChildren = ch => {
    return React.Children.map(ch, child => {
      if (!child) return;
      if (ReactIs.isFragment(child)) {
        return (
          <>
            {React.Children.map(child.props.children, c => (
              <Box>{c}</Box>
            ))}
          </>
        );
      }
      return <Box>{child}</Box>;
    });
  };

  return <Wrapper className={className}>{wrapChildren(children)}</Wrapper>;
}
