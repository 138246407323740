import React from "react";
import styled, { css } from "styled-components";

const Image = styled.img`
  display: block;
  width: 100%;
  border-radius: 3px;

  ${({ src }) =>
    !src &&
    css`
      visibility: hidden;
    `};
`;

function ProfileImage({ src, alt = "" }) {
  return <Image src={src} alt={alt} />;
}

export default ProfileImage;
