import React from "react";
import styled from "styled-components";
import Panel from "../Panel";
import successImg from "../../assets/success.svg";
import failImg from "../../assets/fail.svg";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../../hooks/useCopy";

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Content = styled.div`
  margin-left: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  > * {
    margin-right: 10px;
  }
`;

const RoomSettingsNotification = ({ message, oldRoomAlias, newRoomAlias }) => {
  const [
    successAlt,
    failAlt,
    successMessage,
    successMessageWithRoomAlias,
    failMessage,
  ] = useCopy([
    "settings.successAlt",
    "settings.failAlt",
    "settings.successMessage",
    "settings.successMessageWithRoomAlias",
    "settings.failMessage",
  ]);
  if (message == null) {
    return null;
  }
  let messageText = "";
  if (message === "success") {
    if (newRoomAlias === oldRoomAlias) {
      messageText = successMessage;
    } else {
      const url = `https://${window.location.hostname}/${oldRoomAlias}`;
      messageText = swapCopyVariables(successMessageWithRoomAlias, {
        URL: url,
      });
    }
  } else {
    messageText = failMessage;
  }
  return (
    <Wrapper>
      <Panel>
        <ContentWrapper>
          <Content>
            <img
              src={message === "success" ? successImg : failImg}
              alt={message === "success" ? successAlt : failAlt}
            />
            <div>{messageText}</div>
          </Content>
        </ContentWrapper>
      </Panel>
    </Wrapper>
  );
};

export default RoomSettingsNotification;
