import useVideoContext from "./useVideoContext";

export default function useMainSpeaker() {
  const {
    room: { localParticipant },
    participants,
    screenShareParticipant,
    selectedParticipant,
  } = useVideoContext();
  const currentlySelectedParticipant =
    selectedParticipant &&
    (participants.includes(selectedParticipant) ||
      selectedParticipant === localParticipant)
      ? selectedParticipant
      : null;
  const screenShare =
    screenShareParticipant && screenShareParticipant !== localParticipant
      ? screenShareParticipant
      : null;
  // The participant that is returned is displayed in the main video area. Changing the order of the following
  // variables will change the how the main speaker is determined.
  return (
    currentlySelectedParticipant ||
    screenShare ||
    participants[0] ||
    localParticipant
  );
}
