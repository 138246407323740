import React, { useRef, useState, createContext, useContext } from "react";

export const SlideoutContext = createContext({});

export function SlideoutProvider({ children }) {
  const panelPortalRef = useRef(null);
  const [activePanel, setActivePanel] = useState(null);

  return (
    <SlideoutContext.Provider
      value={{
        panelPortalRef,
        activePanel,
        setActivePanel,
      }}
    >
      {children}
    </SlideoutContext.Provider>
  );
}

export function useSlideoutContext() {
  const context = useContext(SlideoutContext);
  if (!context) {
    throw new Error("useSlideoutContext must be used with a SlideoutProvider");
  }

  return context;
}
