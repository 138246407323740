import { useEffect, useContext, useCallback } from "react";
import { NotificationContext } from "../../Notifications";
import { useWaitingRoomContext } from "../../../providers/WaitingRoomProvider";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../../../hooks/useCopy";
import useVideoContext from "../../../hooks/useVideoContext";
import EventBus from "../../../eventBus";

export default function useNotifyMutedUsers() {
  const [mutedText, unmutedText] = useCopy([
    "notifications.clientMuted",
    "notifications.clientUnmuted",
  ]);
  const { addMessage } = useContext(NotificationContext);
  const { room } = useWaitingRoomContext();
  const { localTracks } = useVideoContext();
  const mute = useCallback(() => {
    addMessage(
      swapCopyVariables(mutedText, { NAME: room.therapistDisplayName }),
    );
  }, [addMessage, room, mutedText]);
  const unmute = useCallback(() => {
    addMessage(
      swapCopyVariables(unmutedText, { NAME: room.therapistDisplayName }),
    );
  }, [addMessage, room, unmutedText]);

  useEffect(() => {
    EventBus.on("mute", mute);
    EventBus.on("unmute", unmute);

    return () => {
      EventBus.off("mute", mute);
      EventBus.off("unmute", unmute);
    };
  }, [localTracks, mute, unmute]);
}
