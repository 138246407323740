import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { DatePickerCalendar } from "react-nice-dates";
import { enUS, es } from "date-fns/locale";

import "react-nice-dates/build/style.css";

import { TextInput } from "@sussex/react-kit/elements";

const getLocale = () => {
  const locales = {
    "en-us": enUS,
    "es-us": es,
  };
  const lang = window.navigator.language.toLowerCase();

  /**
   * default language
   */
  if (typeof locales[lang] === "undefined") {
    return enUS;
  }

  return locales[lang];
};

const InputWrapper = styled.div`
  cursor: pointer;
  @media (min-width: 768px) {
    position: relative;
    overflow: visible;
  }
`;

const FakeInput = styled(TextInput).attrs(() => ({ as: "div" }))`
  min-width: 125px;
  box-sizing: border-box;
  height: 30px;
  font-size: 12px;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.gray};
  padding-left: 30px;
  background: url("${({ icon }) => icon}");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 10px center;
  user-select: none;
`;

const DatePickerWrapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  background: white;
  width: 300px;
  padding: 15px;
  left: 0;
  right: 0;
  margin: 10px auto 0;
  border-radius: 8px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
  transform-origin: top;
  transform: perspective(1000px);
  transition-property: transform, opacity;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.4, 0.2, 0, 1.1);
  z-index: 9;

  @media (min-width: 768px) {
    position: absolute;
    top: 100%;
    width: 400px;
    margin: 8px 0;
  }
`;

const Datepicker = ({ icon, placeholder, value, onDateChange }) => {
  const [visible, setVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const clickOffClose = e => {
      if (
        visible &&
        e.target !== ref.current &&
        !ref.current.contains(e.target)
      ) {
        setVisible(false);
      }
    };

    document.body.style.overflow = visible ? "hidden" : "";
    window.addEventListener("click", clickOffClose);

    return () => {
      document.body.style.overflow = "";
      window.removeEventListener("click", clickOffClose);
    };
  }, [setVisible, visible]);

  return (
    <InputWrapper>
      <FakeInput onClick={() => setVisible(!visible)} icon={icon}>
        {placeholder}
      </FakeInput>
      <div ref={ref}>
        {visible && (
          <DatePickerWrapper>
            <DatePickerCalendar
              date={value}
              locale={getLocale()}
              onDateChange={date => {
                setVisible(false);
                onDateChange(date);
              }}
            />
          </DatePickerWrapper>
        )}
      </div>
    </InputWrapper>
  );
};

export default Datepicker;
