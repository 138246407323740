/**
 * Component: MobileNavigation
 *
 * UI for a sidebar navigation component. Intended to be displayed on mobile
 * screen widths.
 */
import React, { Fragment } from "react";
import styled from "styled-components";
import { H3 } from "@sussex/react-kit/elements";

import useCopy from "../../hooks/useCopy";
import usePreventScroll from "../../hooks/usePreventScroll";
import SPALink from "./SPALink";
import backIcon from "../../assets/back-circle.svg";

/**
 * Wrapper is a full width 100vh fixed container that will allow us to have the
 * proper z-index for the entire menu.
 */
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  z-index: 10;
`;

/**
 * Head contains the subnav title and the back button for closing the subnav
 */
const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.light};
  padding: 25px;
`;

/**
 * SVG back button container that will close the subnav when clicked
 */
const BackButton = styled.div`
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center center;
  background-image: url("${backIcon}");
`;

/**
 * Overlay is underneath the main Content area of the subnav and when clicked
 * will close the subnav
 */
const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background: rgba(84, 94, 110, 0.4);
  z-index: 1;
`;

/**
 * Content is where all the things that belong in the sidebar go. Positioned
 * on the right of the page fixed to 100vh.
 */
const Content = styled.div`
  position: fixed;

  overflow-y: auto;
  background: white;
  top: 0;
  right: 0;
  height: 100vh;
  width: calc(min(80vw, 317px));
  z-index: 2;
  box-shadow: -10px 0px 15px rgba(31, 41, 55, 0.1);
`;

/**
 * Children is the container for all the nav links within the subnav.
 */
const Children = styled.div`
  padding: 25px;
`;

const NavLink = styled.a`
  display: block;
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.default};
  text-decoration: none;
  line-height: 1.15;
  padding: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.light};
    border-radius: 10px;
  }
`;

const NavGroup = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 20px 0;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
`;

const ItemLabel = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.default};
  margin-bottom: 20px;
`;

/**
 * MobileNavigation
 * @param {string}   id     Required attribute that will allow us to handle the
 * special case of the sessions submenu
 *
 * @param {string}   label  Title of the menu, will be displayed in the top of
 * the slide out
 *
 * @param {Array}    items  The navigation objects to be rendered. Must have id,
 * url, and label properties
 *
 * @prarm {Function} header A function component that when provided
 * will render in place of the label
 *
 * @prarm {Function} onClose Close event to remove the navigation component
 */
const MobileNavigation = ({
  id,
  label,
  items,
  header,
  showHeadings,
  onClose,
}) => {
  const [waitingRoomText, historyText, settingsText, helpText] = useCopy([
    "navItem.waitingRoom",
    "navItem.sessionHistory",
    "navItem.settings",
    "navItem.help",
  ]);
  usePreventScroll();
  return (
    <Wrapper>
      <Overlay onClick={onClose} />
      <Content>
        <Head>
          {header ? header() : <H3>{label}</H3>}
          <BackButton onClick={onClose} />
        </Head>
        <Children>
          {id === "sessions" && (
            <>
              <SPALink
                onClick={onClose}
                text={waitingRoomText}
                path="/dashboard"
              />
              <SPALink
                onClick={onClose}
                text={historyText}
                path="/dashboard/history"
              />
              <SPALink
                onClick={onClose}
                text={settingsText}
                path="/dashboard/settings"
              />
              <SPALink
                onClick={onClose}
                text={helpText}
                path="/dashboard/help"
              />
            </>
          )}
          {id !== "sessions" &&
            items.map(item => {
              /**
               * We could get an array of nav tems to render out...or we could get
               * a grouping of nav items to render out.
               */
              const children = item.children ? item.children : [item];
              const ItemWrapper = item.children ? NavGroup : Fragment;
              return (
                <ItemWrapper key={item.id}>
                  {showHeadings && item.label && (
                    <ItemLabel>{item.label}</ItemLabel>
                  )}
                  {children.map(child => (
                    <NavLink key={child.id} href={child.url}>
                      {child.label}
                    </NavLink>
                  ))}
                </ItemWrapper>
              );
            })}
        </Children>
      </Content>
    </Wrapper>
  );
};

export default MobileNavigation;
