/**
 * Hook: useClickOutside
 *
 * Given a ref and an action function, fire the action function
 * when an element not within the parent ref is clicked
 */
import { useEffect } from "react";

const useClickOutside = (ref, action) => {
  useEffect(() => {
    const handleClick = e => {
      if (!ref.current.contains(e.target)) {
        /**
         * Utilize a setTimeout to ensure the close event is the last event on
         * the stack to fire. This ensures that any other event listeners that
         * may be firing based on this click get a chance to fire before we
         * fire the action
         */
        setTimeout(action, 0);
      }
    };

    document.addEventListener("click", handleClick, true);
    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [ref, action]);
};

export default useClickOutside;
