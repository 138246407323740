import React from "react";
import styled from "styled-components";
import Panel from "../Panel";
import successImg from "../../assets/success.svg";

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const Content = styled.div`
  margin-left: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  > * {
    margin-right: 10px;
  }
`;

export default function Notification({ message }) {
  if (!message) {
    return null;
  }

  return (
    <Wrapper>
      <Panel>
        <Content>
          <img src={successImg} alt="" />
          <div>{message}</div>
        </Content>
      </Panel>
    </Wrapper>
  );
}
