import { useCallback, useState } from "react";
import useVideoContext from "./useVideoContext";

export default function useLocalVideoToggle() {
  const {
    room: { localParticipant },
    localTracks,
    createLocalVideoTrack,
    removeLocalVideoTrack,
  } = useVideoContext();

  const videoTrack = localTracks.find(track => track.name.includes("camera"));
  const [isPublishing, setIsPublishing] = useState(false);

  const toggleVideoEnabled = useCallback(async () => {
    if (!isPublishing) {
      setIsPublishing(true);
      if (videoTrack) {
        removeLocalVideoTrack();
        if (localParticipant) {
          const localTrackPublication = localParticipant.unpublishTrack(
            videoTrack,
          );
          // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
          localParticipant.emit("trackUnpublished", localTrackPublication);
        }
      } else {
        const track = await createLocalVideoTrack();
        if (localParticipant && track) {
          await localParticipant.publishTrack(track);
        }
      }
      setIsPublishing(false);
    }
  }, [
    isPublishing,
    videoTrack,
    localParticipant,
    createLocalVideoTrack,
    removeLocalVideoTrack,
  ]);

  return [!!videoTrack, toggleVideoEnabled];
}
