import React, { useEffect } from "react";
import styled from "styled-components";
import { H1, H3, H4, Anchor } from "@sussex/react-kit/elements";
import useCopy from "../hooks/useCopy";
import Panel from "../components/Panel";
import Tabs from "../components/Tabs";
import { useLocation } from "react-router-dom";
import { datadogSetPage, datadogEvent } from "../datadog";

const Header = styled.div`
  margin: 20px 0;
`;

const Body = styled.div`
  display: block;
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 1.6;

  h3 {
    ${H3.componentStyle.rules}
    line-height: 1;
  }

  h4 {
    ${H4.componentStyle.rules}
    line-height: 1;
    margin-bottom: 10px;
  }

  p {
    margin: 20px 0;
  }

  a {
    ${Anchor.componentStyle.rules}
  }

  ul {
    padding-left: 20px;
  }

  hr {
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    margin: 20px 0;
  }
`;

const TabHeader = ({ text, style }) => {
  return <div style={{ display: "flex", ...style }}>{text}</div>;
};

const TabContent = ({ title, body }) => {
  return (
    <>
      <Header>
        <H1>{title}</H1>
      </Header>
      <Body dangerouslySetInnerHTML={{ __html: body }} />
    </>
  );
};

const HelpView = () => {
  const location = useLocation();
  const [helpTitle, helpBody, whatsnewTitle, whatsnewBody] = useCopy([
    "help.title",
    "help.body",
    "whatsnew.title",
    "whatsnew.body",
  ]);

  const params = new URLSearchParams(location.search);
  const activeIndex = params.get("tab") === "whatsnew" ? 1 : 0;

  const onTabChanged = i => {
    const featureName = i === 0 ? "help_panel" : "whats_new_panel";

    datadogEvent({
      category: "help",
      feature: featureName,
      event: "clicked",
      component: "HelpView",
    });
  };

  useEffect(() => {
    datadogSetPage("Help");
  }, []);

  return (
    <Panel>
      <Tabs
        panels={[
          [
            <TabHeader text={helpTitle} style={{ alignItems: "center" }} />,
            <TabContent title={helpTitle} body={helpBody} />,
          ],
          [
            <TabHeader
              text={whatsnewTitle}
              style={{ alignItems: "flex-end" }}
            />,
            <TabContent title={whatsnewTitle} body={whatsnewBody} />,
          ],
        ]}
        activeIndex={activeIndex}
        onTabChanged={onTabChanged}
      />
    </Panel>
  );
};

export default HelpView;
