import React from "react";
import styled from "styled-components";

const Badge = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.default};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-weight: normal;
  background-color: white;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: ${({ theme }) => theme.fontSize.small};
  text-align: center;

  @media (min-width: 768px) {
    width: 55px;
    height: 55px;
    line-height: 55px;
    font-size: 21px;
  }
`;

// Get the first letters of the first and last string in the name.
const initials = name => {
  return name
    .trim()
    .split(" ")
    .filter(str => str.trim().length > 0)
    .map(str => str[0].toUpperCase())
    .filter((str, i, arr) => i === 0 || i === arr.length - 1)
    .join("");
};

export default function InitialsBadge({ name }) {
  if (!name || name.length < 1) {
    return null;
  }
  return <Badge>{initials(name)}</Badge>;
}
