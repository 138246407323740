import React from "react";

import {
  CheckInView,
  CheckedInView,
  ConnectionErrorView,
  VideoErrorView,
} from "../components/ClientCheckin";

export default function CheckinArea({
  connectionError,
  websocketError,
  videoError,
  isConnected,
  video,
  canvas,
  onVideoError,
  onConnectionError,
  onReset,
  onSettingsOpen,
}) {
  if (videoError) {
    return <VideoErrorView reset={onReset} openSettings={onSettingsOpen} />;
  }
  if (connectionError) {
    return <ConnectionErrorView reset={() => window.location.reload()} />;
  }
  if (websocketError) {
    return <ConnectionErrorView reset={() => window.location.reload()} />;
  }
  return isConnected ? (
    <CheckedInView />
  ) : (
    <CheckInView
      video={video}
      canvasRef={canvas}
      onVideoError={onVideoError}
      onConnectionError={onConnectionError}
    />
  );
}
