import React from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  color: white;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.normal};
`;

export default function Loading() {
  const [loadingText] = useCopy(["global.cameraLoading"]);
  return <Wrapper>{loadingText}</Wrapper>;
}
