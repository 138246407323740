import React, { useEffect } from "react";
import styled from "styled-components";
import ErrorAlert from "../ErrorAlert";
import useCopy from "../../hooks/useCopy";
import { datadogEvent } from "../../datadog";

const Wrapper = styled.div`
  margin: 20px auto;
`;

export default function ConnectError({ reason }) {
  const [errorMessage] = useCopy(["dashboard.connectionError"]);

  useEffect(() => {
    datadogEvent({
      category: "dashboard",
      feature: "connect_error",
      event: "error",
      component: "Dashboard.ConnectError",
      metadata: { reason },
    });
  }, [reason]);

  return (
    <Wrapper>
      <ErrorAlert message={errorMessage} />
    </Wrapper>
  );
}
