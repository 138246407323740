import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import useVideoContext from "../../hooks/useVideoContext";
import { getLocalAudioTrack, getLocalVideoTrack } from "../../utils";

const DevicesContext = createContext(null);

export function DevicesProvider({ children }) {
  const {
    isAcquiringLocalTracks,
    localTracks,
    replaceLocalAudioTrack,
    replaceLocalVideoTrack,
  } = useVideoContext();
  const [devices, setDevices] = useState([]);
  const [audioInputDevice, setAudioInputDevice] = useState(null);
  const [audioOutputDevice, setAudioOutputDevice] = useState(null);
  const [videoInputDevice, setVideoInputDevice] = useState(null);
  const [working, setWorking] = useState(false);

  const replaceAudioInputDevice = useCallback(
    (device, inSession = false) => {
      if (!device) {
        return;
      }
      setAudioInputDevice(device);
      if (audioInputDevice) {
        setWorking(true);
        const p = replaceLocalAudioTrack(device);
        if (p) {
          p.catch(() => {
            if (inSession) {
              // go back to the previous device
              setAudioInputDevice(audioInputDevice);
            }
          }).finally(() => {
            setWorking(false);
          });
        } else {
          setWorking(false);
        }
      }
    },
    [audioInputDevice, replaceLocalAudioTrack],
  );

  const replaceAudioOutputDevice = useCallback(device => {
    if (!device) {
      return;
    }
    setAudioOutputDevice(device);
  }, []);

  const replaceVideoInputDevice = useCallback(
    (device, inSession = false) => {
      if (!device) {
        return;
      }
      setVideoInputDevice(device);
      if (videoInputDevice) {
        setWorking(true);
        const p = replaceLocalVideoTrack(device);
        if (p) {
          p.catch(() => {
            if (inSession) {
              // go back to the previous device
              setVideoInputDevice(videoInputDevice);
            }
          }).finally(() => {
            setWorking(false);
          });
        } else {
          setWorking(false);
        }
      }
    },
    [videoInputDevice, replaceLocalVideoTrack],
  );

  const checkDevices = useCallback(async () => {
    if (isAcquiringLocalTracks) {
      return;
    }

    const allDevices = await getDevices();

    if (JSON.stringify(allDevices) !== JSON.stringify(devices)) {
      setDevices(allDevices);
    }

    const autoReplace = (
      devicesSet,
      currentDevice,
      defaultDevice,
      replaceFunc,
    ) => {
      if (defaultDevice) {
        const currentAvailable =
          currentDevice &&
          devicesSet.find(
            d =>
              d.deviceId === currentDevice.deviceId &&
              d.groupId === currentDevice.groupId,
          );

        const defaultChanged =
          currentDevice &&
          currentDevice.isDefault &&
          (currentDevice.deviceId !== defaultDevice.deviceId ||
            currentDevice.groupId !== defaultDevice.groupId);

        if (!currentAvailable || defaultChanged) {
          replaceFunc(defaultDevice);
        }
      }
    };

    const getInitialDevice = (devicesSet, track) => {
      if (!track) {
        return null;
      }
      const settings = track.mediaStreamTrack.getSettings();
      return (
        settings &&
        devicesSet.find(
          d =>
            d.deviceId === settings.deviceId && d.groupId === settings.groupId,
        )
      );
    };

    // auto replace devices if the current ones are not
    // available anymore or if the default devices changed
    const audioInputDevices = getAudioInputDevices(allDevices);
    const audioTrack = getLocalAudioTrack(localTracks);
    const initialAudioInputDevice = audioInputDevice
      ? null
      : getInitialDevice(audioInputDevices, audioTrack);
    const defAudioInputDevice =
      initialAudioInputDevice || getDefaultDevice(audioInputDevices);
    autoReplace(
      audioInputDevices,
      audioInputDevice,
      defAudioInputDevice,
      replaceAudioInputDevice,
    );

    const audioOutputDevices = getAudioOutputDevices(allDevices);
    const defAudioOutputDevice = getDefaultDevice(audioOutputDevices);
    autoReplace(
      audioOutputDevices,
      audioOutputDevice,
      defAudioOutputDevice,
      replaceAudioOutputDevice,
    );

    const videoInputDevices = getVideoInputDevices(allDevices);
    const videoTrack = getLocalVideoTrack(localTracks);
    const initialVideoInputDevice = videoInputDevice
      ? null
      : getInitialDevice(videoInputDevices, videoTrack);
    const defVideoInputDevice =
      initialVideoInputDevice || getDefaultDevice(videoInputDevices);
    autoReplace(
      videoInputDevices,
      videoInputDevice,
      defVideoInputDevice,
      replaceVideoInputDevice,
    );
  }, [
    isAcquiringLocalTracks,
    localTracks,
    devices,
    audioInputDevice,
    replaceAudioInputDevice,
    audioOutputDevice,
    replaceAudioOutputDevice,
    videoInputDevice,
    replaceVideoInputDevice,
  ]);

  useEffect(() => {
    const interval = setInterval(checkDevices, 2000);
    return () => clearInterval(interval);
  }, [checkDevices]);

  return (
    <DevicesContext.Provider
      value={{
        devices,
        audioInputDevice,
        replaceAudioInputDevice,
        audioOutputDevice,
        replaceAudioOutputDevice,
        videoInputDevice,
        replaceVideoInputDevice,
        working,
      }}
    >
      {children}
    </DevicesContext.Provider>
  );
}

export function useDevicesContext() {
  const context = useContext(DevicesContext);
  if (!context) {
    throw new Error("useDevicesContext must be used within an DevicesProvider");
  }

  return context;
}

export const getAudioInputDevices = devices =>
  devices.filter(device => device.kind === "audioinput");

export const getAudioOutputDevices = devices =>
  devices.filter(device => device.kind === "audiooutput");

export const getVideoInputDevices = devices =>
  devices.filter(device => device.kind === "videoinput");

const getDevices = async () => {
  // This function gets the available devices and unifies the list
  // so that each physical device appears only once, and the default
  // devices are marked with the isDefault flag. We do this because
  // different browsers in different OSs return the list of devices
  // in different ways. In some cases we get one item per device, and
  // in other cases we get duplicated devices but with special ids like
  // for example "default" or "communications". Those special ids are
  // managed internally by the browsers and we don't really know when
  // they change.
  const res = [];

  try {
    const devices = await navigator.mediaDevices.enumerateDevices();

    if (devices) {
      const defaults = [];

      devices.forEach(d => {
        d.isDefault = false;

        if (d.deviceId === "default") {
          defaults.push(d);
        } else if (d.deviceId !== "" && d.deviceId !== "communications") {
          res.push(d);
        }
      });

      defaults.forEach(d => {
        const ind = res.findIndex(
          rd => rd.kind === d.kind && rd.groupId === d.groupId,
        );
        if (ind >= 0) {
          res[ind].isDefault = true;
        }
      });
    }
  } catch (e) {}

  return res;
};

const getDefaultDevice = devices => {
  if (!devices) {
    return null;
  }

  let defDevice = devices.find(d => d.isDefault);

  if (!defDevice) {
    defDevice = devices[0];
  }

  return defDevice;
};
