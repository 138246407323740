import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";

import { isMobile } from "@sussex/react-kit/utils";

import useCopy from "../../hooks/useCopy";
import { useSessionMenuContext } from "../../providers/SessionMenuProvider";
import useVideoContext from "../../hooks/useVideoContext";
import usePIP from "../../hooks/usePIP";
import PIPIcon from "../../assets/pip.svg";
import settingsIcon from "../../assets/audio-video-settings.svg";
import fullscreenIcon from "../../assets/fullscreen-icon.svg";
import participantListIcon from "../../assets/slide.svg";
import screenshareIcon from "../../assets/screenshare-icon.svg";
import stopScreenshareIcon from "../../assets/screenshare-active-icon.svg";
import {
  canFullscreen,
  isFullscreen,
  requestFullscreen,
  exitFullscreen,
} from "./fullscreenUtils";
import { datadogEvent } from "../../datadog";

const Wrapper = styled.nav`
  border-radius: 3px;
  background: white;
  padding: 30px;
`;

const ListGroup = styled.ul`
  margin: 0;
  padding: 0 0 25px 0;
  position: relative;

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.border};
    padding: 25px 0 0 0;
  }
`;

const ListItem = styled.li`
  padding: 0 15px 0 35px;
  cursor: pointer;
  white-space: nowrap;
  list-style: none;
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.normal};
  line-height: 22px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 50%;
      cursor: default;
    `};

  & + & {
    margin-top: 25px;
  }

  &:before {
    position: absolute;
    content: " ";
    left: 0;
    width: 22px;
    height: 22px;
    background-image: url(${({ icon }) => icon});
    background-size: contain;
    background-repeat: no-repeat;
    ${({ flipIcon }) =>
      flipIcon &&
      css`
        transform: rotate(180deg);
      `};
  }
`;

const SettingsMenu = () => {
  const {
    setMenuOpen,
    setChangingAudioVideoSettings,
    participantListVisible,
    setParticipantListVisible,
  } = useSessionMenuContext();
  const {
    room,
    toggleScreenShare,
    isScreenSharing,
    screenShareParticipant,
  } = useVideoContext();

  const { pipEnabled, startPIP, hasPIP, stopPIP } = usePIP();
  const menuRef = useRef(null);
  const [
    audioVideoCopy,
    participantListVisibleCopy,
    participantListHiddenCopy,
    fullscreenText,
    exitFullscreenText,
    screenshareText,
    stopScreenshareText,
    screenshareInUseText,
    enablePIP,
    disablePIP,
  ] = useCopy([
    "settingsMenu.audioVideo",
    "settingsMenu.participantList.visible",
    "settingsMenu.participantList.hidden",
    "settingsMenu.fullscreen",
    "settingsMenu.exitFullscreen",
    "settingsMenu.screenshare",
    "settingsMenu.stopScreenshare",
    "settingsMenu.screenshareInUse",
    "settingsMenu.enablePIP",
    "settingsMenu.disablePIP",
  ]);

  const screenshareInUse =
    screenShareParticipant && screenShareParticipant !== room.localParticipant;
  const canScreenShare =
    !isMobile &&
    navigator.mediaDevices &&
    navigator.mediaDevices.getDisplayMedia;

  useEffect(() => {
    if (!menuRef.current) return;
    const clickOffClose = e => {
      if (menuRef.current !== e.target && !menuRef.current.contains(e.target)) {
        setMenuOpen(false);
      }
    };
    window.addEventListener("click", clickOffClose);
    return () => {
      window.removeEventListener("click", clickOffClose);
    };
  }, [setMenuOpen]);

  const openMediaSettings = () => {
    setChangingAudioVideoSettings(true);
    setMenuOpen(false);

    datadogEvent({
      category: "media_settings",
      feature: "panel",
      event: "opened",
      component: "SettingsMenu",
    });
  };

  const handleFullscreenClick = () => {
    datadogEvent({
      category: "session",
      feature: "fullscreen",
      event: "clicked",
      component: "SettingsMenu",
    });

    if (isFullscreen()) {
      exitFullscreen();
    } else {
      requestFullscreen();
    }

    setMenuOpen(false);
  };

  const handleToggleThumbnailsClick = () => {
    datadogEvent({
      category: "session",
      feature: "thumbnails",
      event: participantListVisible ? "hidden" : "shown",
      component: "SettingsMenu",
    });

    setParticipantListVisible(!participantListVisible);
    setMenuOpen(false);
  };

  const handleScreenShareClick = () => {
    if (!screenshareInUse) {
      toggleScreenShare();
      setMenuOpen(false);

      datadogEvent({
        category: "session",
        feature: "screenshare",
        event: "clicked",
        component: "SettingsMenu",
      });
    }
  };

  const togglePIP = () => {
    hasPIP() ? stopPIP() : startPIP();
    setMenuOpen(false);
  };

  return (
    <Wrapper ref={menuRef}>
      <ListGroup>
        {canFullscreen && !isMobile && (
          <ListItem icon={fullscreenIcon} onClick={handleFullscreenClick}>
            {isFullscreen() ? exitFullscreenText : fullscreenText}
          </ListItem>
        )}
        <ListItem
          flipIcon={participantListVisible}
          icon={participantListIcon}
          onClick={handleToggleThumbnailsClick}
        >
          {participantListVisible
            ? participantListVisibleCopy
            : participantListHiddenCopy}
        </ListItem>
        {canScreenShare && (
          <ListItem
            icon={isScreenSharing ? stopScreenshareIcon : screenshareIcon}
            onClick={handleScreenShareClick}
            disabled={screenshareInUse}
          >
            <span title={screenshareInUse ? screenshareInUseText : ""}>
              {isScreenSharing ? stopScreenshareText : screenshareText}
            </span>
          </ListItem>
        )}
        {pipEnabled() && (
          <ListItem icon={PIPIcon} onClick={togglePIP}>
            {hasPIP() ? disablePIP : enablePIP}
          </ListItem>
        )}
      </ListGroup>
      <ListGroup>
        <ListItem icon={settingsIcon} onClick={openMediaSettings}>
          {audioVideoCopy}
        </ListItem>
      </ListGroup>
    </Wrapper>
  );
};

export default SettingsMenu;
