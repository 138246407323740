import React, {
  createContext,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";

import chime from "../../assets/chime.mp3";
import { useDevicesContext } from "../../providers/DevicesProvider";

const AudioAlertContext = createContext();

export function AudioAlertProvider({ children }) {
  const { audioOutputDevice } = useDevicesContext();
  const bellChimeRef = useRef(new Audio(chime));

  const playChime = useCallback(() => {
    if (bellChimeRef.current.play) {
      bellChimeRef.current.muted = false;
      const p = bellChimeRef.current.play();
      if (p) {
        p.catch(() => {});
      }
    }
  }, []);

  useEffect(() => {
    // memoize it
    const handler = () => {
      let triggered = false;
      return () => {
        if (!triggered && bellChimeRef.current.play) {
          bellChimeRef.current.muted = true;
          const p = bellChimeRef.current.play();
          if (p) {
            p.catch(() => {});
          }
        }
        triggered = true;
      };
    };

    document.addEventListener("touchstart", handler());
    document.addEventListener("mousedown", handler());

    return () => {
      document.removeEventListener("touchstart", handler());
      document.removeEventListener("mousedown", handler());
    };
  }, []);

  useEffect(() => {
    if (!audioOutputDevice) {
      return;
    }
    if (bellChimeRef.current.setSinkId) {
      bellChimeRef.current
        .setSinkId(audioOutputDevice.deviceId)
        .catch(() => {});
    }
  }, [audioOutputDevice]);

  return (
    <AudioAlertContext.Provider value={{ playChime }}>
      {children}
    </AudioAlertContext.Provider>
  );
}

export function useAudioAlertContext() {
  const context = useContext(AudioAlertContext);
  if (!context) {
    throw new Error(
      "useAudioAlertContext must be used within a AudioAlertContext",
    );
  }

  return context;
}
