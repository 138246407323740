import { useEffect, useState, useRef } from "react";

export default function usePublications(participant) {
  const [publications, setPublications] = useState([]);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    // Reset the publications when the 'participant' variable changes.
    setPublications(Array.from(participant.tracks.values()));

    /**
     * Sometimes publications are published without a track. This will update
     * our publications list whenever a track is subscribed to.
     */
    const trackAdded = () => {
      if (isMounted.current) {
        setPublications(prevPublications => [...prevPublications]);
      }
    };
    const publicationAdded = publication => {
      if (isMounted.current) {
        setPublications(prevPublications => [...prevPublications, publication]);
      }
    };
    const publicationRemoved = publication => {
      if (isMounted.current) {
        setPublications(prevPublications =>
          prevPublications.filter(p => p !== publication),
        );
      }
    };

    participant.on("trackSubscribed", trackAdded);
    participant.on("trackPublished", publicationAdded);
    participant.on("trackUnpublished", publicationRemoved);
    return () => {
      participant.off("trackSubscribed", trackAdded);
      participant.off("trackPublished", publicationAdded);
      participant.off("trackUnpublished", publicationRemoved);
    };
  }, [participant]);

  return publications;
}
