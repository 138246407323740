import { useEffect, useRef } from "react";
import { useDevicesContext } from "../../providers/DevicesProvider";

export default function AudioTrack({ track }) {
  const { audioOutputDevice } = useDevicesContext();
  const audioEl = useRef(null);

  useEffect(() => {
    audioEl.current = track.attach();
    audioEl.current.setAttribute("data-cy-audio-track-name", track.name);
    document.body.appendChild(audioEl.current);
    return () => track.detach(audioEl.current).remove();
  }, [track]);

  useEffect(() => {
    if (audioEl.current === null || !audioOutputDevice) {
      return;
    }
    if (audioEl.current.setSinkId) {
      audioEl.current.setSinkId(audioOutputDevice.deviceId).catch(() => {});
    }
  }, [audioOutputDevice]);

  return null;
}
