import { useState, useEffect, useRef } from "react";

export default function useInactive(threshold) {
  const activeTimer = useRef(null);
  const [inactive, setInactive] = useState(false);

  useEffect(() => {
    activeTimer.current = setTimeout(() => {
      setInactive(true);
    }, threshold);
  }, [threshold]);

  useEffect(() => {
    const createTimer = evt => {
      setInactive(false);
      clearTimeout(activeTimer.current);
      activeTimer.current = setTimeout(() => {
        setInactive(true);
      }, threshold);
    };

    const events = ["mousemove", "mousedown", "touchstart", "keydown"];

    events.forEach(evt => {
      document.addEventListener(evt, createTimer);
    });

    return () => {
      clearTimeout(activeTimer.current);
      events.forEach(evt => {
        document.removeEventListener(evt, createTimer);
      });
    };
  }, [threshold]);

  return inactive;
}
