// See: https://www.twilio.com/docs/video/tutorials/developing-high-quality-video-applications#concepts-and-terminology
// for recommended resolutions.
const resolutions = [
  {
    labelKey: "sessioncontrols.resolutions.high",
    dimensions: { width: 1280, height: 720 },
  },
  {
    labelKey: "sessioncontrols.resolutions.medium",
    dimensions: { width: 960, height: 540 },
  },
  {
    labelKey: "sessioncontrols.resolutions.low",
    dimensions: { width: 640, height: 480 },
  },
];

const desktopVideoConfig = {
  ...resolutions[0].dimensions,
  frameRate: 24,
};

// For now, publish mobile in hd and let twilio scale it.
// We can reduce to the recommended vga when quality is improved.
const mobileVideoConfig = {
  ...resolutions[2].dimensions,
  facingMode: "user",
  frameRate: 24,
};

// A mix of grid and collaboration mode recommendations because we're really
// doing a collaboration room, but forced to use grid mode due to the codec
// issues: https://www.twilio.com/docs/video/tutorials/developing-high-quality-video-applications#grid-mode
// See: https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#ConnectOptions
// for all available connection options.
const desktopOptions = {
  video: desktopVideoConfig,
  maxAudioBitrate: 16000,
};

const mobileOptions = {
  video: mobileVideoConfig,
  maxAudioBitrate: 16000,
};

export {
  desktopOptions,
  mobileOptions,
  desktopVideoConfig,
  mobileVideoConfig,
  resolutions,
};
