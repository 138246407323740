/**
 * Browser compatible function that will return true if the browser document has a fullscreen element
 */
export const isFullscreen = () =>
  document.fullscreenElement ||
  document.mozFullScreenElement ||
  document.webkitFullscreenElement ||
  document.msFullscreenElement;

/**
 * Browser compatible function to determine if fullscreening is possible
 */
export const canFullscreen = () => {
  const elem = document.documentElement;
  return !!(
    elem.requestFullscreen ||
    elem.msRequestFullscreen ||
    elem.mozRequestFullScreen ||
    elem.webkitRequestFullscreen
  );
};

/**
 * Browser compatible function that will fullscreen the document
 */
export const requestFullscreen = () => {
  const elem = document.documentElement;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
  }
};

/**
 * Browser compatible function that will exit fullscreen
 */
export const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
};
