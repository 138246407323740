import React, { useState, useEffect } from "react";
import { requestIdentityVerification } from "../../httpapi";
import { getLoginUrl } from "../../utils";
import { useAppStateContext, actions } from "../../providers/AppStateProvider";

const { WEBSOCKET_SET_CONNECT } = actions;

const authorize = (onAuthSuccess, onAuthFailure) => async () => {
  // request server verification
  const result = await requestIdentityVerification();

  // callback to success
  if (result.success) {
    return onAuthSuccess(result);
  }

  // callback with failure;
  return onAuthFailure(result.error);
};

// Authenticated requests application authentication via the
// the identity token.
export default function Authenticated({ children }) {
  const [authorized, setAuthorized] = useState(false);
  const { dispatch } = useAppStateContext();

  useEffect(() => {
    authorize(
      () => {
        setAuthorized(true);
        dispatch({
          type: WEBSOCKET_SET_CONNECT,
          connect: true,
        });
      },
      e => {
        if (!e || !e.status) {
          window.location = process.env.REACT_APP_MEMBER_WEB;
          return;
        }
        switch (e.status) {
          case 401:
            // Member login with a redirect back to sessions
            window.location = getLoginUrl(window.location);
            break;
          case 403:
            // Sessions marketing page so user can sign BAA
            window.location = `${process.env.REACT_APP_MEMBER_WEB}/?url=sessions`;
            break;
          case 404:
            // Member home with not eligible message.
            window.location = `${process.env.REACT_APP_MEMBER_WEB}/?msg=su`;
            break;
          default:
            // Member home
            window.location = process.env.REACT_APP_MEMBER_WEB;
        }
      },
    )();
  }, [dispatch]);

  return <>{authorized ? children : null}</>;
}
