import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";
import styled, { css } from "styled-components";

import { useSlideoutContext } from "./SlideoutProvider";
import Panel from "./Panel";
import { transitionName, transitionTime, panelWidth } from "./SlideoutConsts";
import { isMobile } from "@sussex/react-kit/utils";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const PanelWrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9;
  transition: transform ${transitionTime}ms ease-in-out;
  overflow: hidden;
  top: 0;
  background: white;
  ${({ position }) =>
    position === "right"
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `}

  &.${transitionName}-enter {
    transform: translateX(
      ${({ position }) => (position === "right" ? "100%" : "-100%")}
    );
  }

  &.${transitionName}-enter-active {
    transform: translateX(0);
  }

  &.${transitionName}-exit {
    transform: translateX(0);
  }

  &.${transitionName}-exit-active {
    transform: translateX(
      ${({ position }) => (position === "right" ? "100%" : "-100%")}
    );
  }

  ${({ mobile }) =>
    !mobile &&
    css`
      width: ${panelWidth};
      box-shadow: 0px 0px 20px ${({ theme }) => theme.colors.shadow};
    `}
`;

const Panels = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  transition: transform ${transitionTime}ms ease-in-out;

  & > * {
    flex: 0 0 auto;
    transition: opacity ${transitionTime}ms ease-in-out;

    &:nth-child(1) {
      opacity: 1;
    }

    &:nth-child(2) {
      opacity: 0;
    }
  }

  &.active {
    transform: translateX(-100%);

    & > :nth-child(1) {
      opacity: 0;
    }

    & > :nth-child(2) {
      opacity: 1;
    }
  }
`;

const ChildPanel = styled.div`
  width: 100%;
`;

export default function SlideoutPanel({
  children,
  header,
  active,
  onClose,
  overlap,
  childPanelActive,
  childPanel,
  childPanelCleanup,
  position = "right",
}) {
  const { panelPortalRef, activePanel, setActivePanel } = useSlideoutContext();
  const activeRef = useRef(active);
  const replacedRef = useRef(false);
  const xs = !useMediaQuery("(min-width:768px)");
  const mobile = isMobile || xs;

  useEffect(() => {
    // Escape if not changing active states, otherwise reset active ref.
    if (activeRef.current === active) return;
    activeRef.current = active;

    // Escape if the panel is being replaced, otherwise reset the replaced ref.
    if (replacedRef.current && !active) return;
    replacedRef.current = false;

    if (active) {
      activePanel && activePanel.replace();
      setActivePanel({
        replace: () => {
          replacedRef.current = true;
          onClose();
        },
        position: position,
        overlap: overlap,
      });
    } else {
      setActivePanel(null);
    }
  }, [active, onClose, position, overlap, activePanel, setActivePanel]);

  // Keep active panel updated with dependencies
  useEffect(() => {
    if (active) {
      setActivePanel({
        replace: () => {
          replacedRef.current = true;
          onClose();
        },
        position: position,
        overlap: overlap,
      });
    }
  }, [active, onClose, position, overlap, setActivePanel]);

  // Clean up active panel if removed without closing
  useEffect(() => {
    return () => {
      if (activeRef.current) {
        setActivePanel(null);
      }
    };
  }, [setActivePanel]);

  // Optionally clean up child panel after it is closed.
  useEffect(() => {
    if (childPanel && childPanelCleanup && !childPanelActive) {
      setTimeout(() => {
        childPanelCleanup();
      }, transitionTime);
    }
  }, [childPanel, childPanelActive, childPanelCleanup]);

  const renderPanel = () => {
    return (
      <PanelWrapper position={position} mobile={mobile}>
        <Panels className={childPanelActive ? "active" : null}>
          <Panel header={header} onClose={onClose}>
            {children}
          </Panel>
          <ChildPanel>{childPanel}</ChildPanel>
        </Panels>
      </PanelWrapper>
    );
  };

  return (
    panelPortalRef.current &&
    createPortal(
      mobile && active ? (
        renderPanel()
      ) : (
        <CSSTransition
          in={active}
          timeout={transitionTime}
          classNames={transitionName}
          unmountOnExit
        >
          {renderPanel()}
        </CSSTransition>
      ),
      panelPortalRef.current,
    )
  );
}
