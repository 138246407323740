import { useEffect } from "react";
import useVideoContext from "./useVideoContext";
import { getLocalAudioTrack } from "../utils";
import EventBus from "../eventBus";

export default function useTherapistMuted() {
  const { localTracks } = useVideoContext();

  useEffect(() => {
    const audioTrack = getLocalAudioTrack(localTracks);
    const mute = () => {
      if (!audioTrack) {
        return;
      }
      audioTrack.disable();
    };
    const unmute = () => {
      if (!audioTrack) {
        return;
      }
      audioTrack.enable();
    };

    EventBus.on("mute", mute);
    EventBus.on("unmute", unmute);

    return () => {
      EventBus.off("mute", mute);
      EventBus.off("unmute", unmute);
    };
  }, [localTracks]);
}
