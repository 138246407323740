import React, { useState, createContext, useContext } from "react";

export const SessionHistoryContext = createContext({});

export function SessionHistoryProvider({ children }) {
  const [history, setHistory] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState("0");
  const [limit, setLimit] = useState("10");

  const [startFilter, setStartFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");

  return (
    <SessionHistoryContext.Provider
      value={{
        history,
        setHistory,
        error,
        setError,
        loading,
        setLoading,
        offset,
        setOffset,
        limit,
        setLimit,
        startFilter,
        setStartFilter,
        nameFilter,
        setNameFilter,
      }}
    >
      {children}
    </SessionHistoryContext.Provider>
  );
}

export function useSessionHistoryContext() {
  const context = useContext(SessionHistoryContext);
  if (!context) {
    throw new Error(
      "userSessionHistoryContext must be used within a SessionHistoryProvider",
    );
  }

  return context;
}
