/**
 * Component: Profile Image
 *
 * This is a simple profile image circle for use in several contexts within the
 * top navigation bar and menus.
 */
import styled from "styled-components";

const ProfileImage = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-image: url("${({ src }) => src}");
  background-size: cover;
  background-position-y: -4px;
`;

export default ProfileImage;
