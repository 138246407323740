import React, { useState } from "react";
import styled from "styled-components";
import { Select } from "@sussex/react-kit/elements";
import {
  useDevicesContext,
  getAudioInputDevices,
} from "../../../providers/DevicesProvider";
import TestDevice from "./TestDevice";
import useCopy from "../../../hooks/useCopy";
import { useAppStateContext } from "../../../providers/AppStateProvider";
import { isWin } from "@sussex/react-kit/utils";
import { datadogEvent } from "../../../datadog";

const Wrapper = styled.div`
  display: flex;
`;

const Left = styled.div`
  flex-grow: 1;
`;

const Right = styled.div`
  flex-shrink: 1;
`;

export default function SelectAudioInputTrack({ testsEnabled }) {
  const {
    devices,
    audioInputDevice,
    replaceAudioInputDevice,
    working,
  } = useDevicesContext();
  const audioInputDevices = getAudioInputDevices(devices);
  const [microphoneText] = useCopy(["sessioncontrols.microphone"]);
  const {
    state: { session },
  } = useAppStateContext();
  const [defaultChanged, setDefaultChanged] = useState(false);

  const onChange = e => {
    const parts = e.target.value.split("|");
    if (parts.length > 1) {
      const deviceId = parts[0];
      const groupId = parts[1];
      const device = audioInputDevices.find(
        d => d.deviceId === deviceId && d.groupId === groupId,
      );

      replaceAudioInputDevice(device, session.active);
      setDefaultChanged(true);

      datadogEvent({
        category: "media_settings",
        feature: "audio_input",
        event: "changed",
        component: "SessionControls.MediaSelection.SelectAudioInputTrack",
        metadata: {
          device: device.label,
        },
      });
    }
  };

  // If the selected device didn't change, we test the one
  // that was selected by default when we obtained the local
  // tracks. That's done by just using an empty device id for
  // the test. After the selected device is changed, we switch
  // to run the test with the specific device id.
  // For some reason that doesn't work on Windows, so we need
  // to provide the device id from the beginning on that case.
  const deviceToTest =
    audioInputDevice && (isWin || defaultChanged)
      ? audioInputDevice
      : { kind: "audioinput" };

  return (
    <Select
      label={
        <Wrapper>
          <Left>{microphoneText}</Left>
          <Right>{testsEnabled && <TestDevice device={deviceToTest} />}</Right>
        </Wrapper>
      }
      id="selectAudioInputDevice"
      value={
        audioInputDevice
          ? audioInputDevice.deviceId + "|" + audioInputDevice.groupId
          : ""
      }
      options={audioInputDevices.map(d => ({
        value: d.deviceId + "|" + d.groupId,
        text: d.label,
      }))}
      onChange={onChange}
      disabled={working}
    />
  );
}
