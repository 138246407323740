import React, { useState, createContext, useContext } from "react";

export const SessionMenuContext = createContext({});

export function SessionMenuProvider({ children }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [participantListVisible, setParticipantListVisible] = useState(true);
  const [changingAudioVideoSettings, setChangingAudioVideoSettings] = useState(
    false,
  );

  return (
    <SessionMenuContext.Provider
      value={{
        menuOpen,
        setMenuOpen,
        changingAudioVideoSettings,
        setChangingAudioVideoSettings,
        participantListVisible,
        setParticipantListVisible,
      }}
    >
      {children}
    </SessionMenuContext.Provider>
  );
}

export function useSessionMenuContext() {
  const context = useContext(SessionMenuContext);
  if (!context) {
    throw new Error(
      "useSessionMenuContext must be used within a SessionMenuProvider",
    );
  }

  return context;
}
