import React from "react";
import { useAppStateContext } from "../../providers/AppStateProvider";
import { ChatButton } from "../SessionControls/Buttons";

const ClientWaitingRoomChatButton = () => {
  const {
    state: { therapist },
  } = useAppStateContext();

  return <ChatButton channel={therapist.participantId} inSession={false} />;
};

export default ClientWaitingRoomChatButton;
