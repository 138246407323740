import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { CloseButton } from "@sussex/react-kit/elements";
import Menu from "../SidebarLayout/Menu";
import useCopy from "../../hooks/useCopy";

const Header = styled.div`
  display: flex;
  padding: 18px;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.light};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CloseWrapper = styled.div`
  margin-top: 4px;
`;

const Body = styled.div`
  flex: 1;
`;

const NavMenu = ({ onClose }) => {
  const [appName] = useCopy(["header.appName"]);
  const location = useLocation();
  return (
    <Wrapper>
      <Header>
        <Title>{appName}</Title>
        <CloseWrapper>
          <CloseButton close={onClose} width="20px" height="20px" fill="#000" />
        </CloseWrapper>
      </Header>
      <Body>
        <Menu activePage={location.pathname} onClick={onClose} />
      </Body>
    </Wrapper>
  );
};

export default NavMenu;
