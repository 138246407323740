import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";

import useVideoContext from "../../hooks/useVideoContext";
import usePIP from "../../hooks/usePIP";

const Video = styled.video`
  display: block;
  width: 100%;
  height: auto;
  pointer-events: none;

  ${({ isLocal }) =>
    isLocal &&
    css`
      transform: rotateY(180deg);
    `};
`;

export default function VideoTrack({
  setVideo,
  track,
  isLocal,
  isScreenShare,
  priority,
  isMain,
}) {
  const videoRef = useRef(null);

  const { stopPIP, hasPIP } = usePIP();
  const { setPrimaryVideoTrack } = useVideoContext();

  useEffect(() => {
    if (isMain) {
      setPrimaryVideoTrack(videoRef.current);

      // When the main participant view goes away (user
      // turns off camera / disconnects / or another
      // track is pinned) We stop pipping and return to
      // normal view.
      return () => {
        if (hasPIP()) {
          stopPIP();
        }
        setPrimaryVideoTrack(null);
      };
    }
  }, [setPrimaryVideoTrack, videoRef, isMain, hasPIP, stopPIP]);

  useEffect(() => {
    if (!setVideo) return;
    setVideo(videoRef.current);
  }, [setVideo]);

  useEffect(() => {
    const el = videoRef.current;
    if (!track || !el) return;
    el.muted = true;
    if (track.setPriority && priority) {
      track.setPriority(priority);
    }
    track.attach(el);
    return () => {
      track.detach(el);
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [videoRef, track, priority, isMain]);

  return (
    <Video ref={videoRef} isLocal={isLocal} isScreenShare={isScreenShare} />
  );
}
