import React from "react";
import styled, { css } from "styled-components";
import TherapistAvailability from "../TherapistAvailability";
import useParticipantInfo from "../../hooks/useParticipantInfo";

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ center }) =>
    center &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
`;

const ImageWrapper = styled.img`
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
`;

const Name = styled.div`
  color: ${({ theme }) => theme.colors.default};
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.normal};
`;

export default function ChatHeader({ isTherapist, participant = {} }) {
  const { displayName, photoUrl } = useParticipantInfo(
    participant.participantId,
  );
  if (!isTherapist) {
    return <TherapistAvailability isTherapist={false} />;
  }

  if (!participant) {
    return null;
  }

  return (
    <>
      <Wrapper>
        <ImageWrapper src={photoUrl} />
        <Name>{displayName}</Name>
      </Wrapper>
    </>
  );
}
