import React, { useState } from "react";
import styled, { css } from "styled-components";

const Headers = styled.div`
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  margin-bottom: 20px;
`;
const HeaderItem = styled.div`
  cursor: pointer;
  letter-spacing: 0.25px;
  padding-bottom: 10px;
  margin-right: 40px;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-family: ${({ theme }) => theme.fonts.semiBold};
  text-transform: uppercase;

  ${({ active }) =>
    active &&
    css`
      font-family: ${({ theme }) => theme.fonts.bold};
      border-bottom: 2px solid ${({ theme }) => theme.colors.default};
    `}
`;

const Body = styled.div`
  background: white;
`;

const Tabs = ({ panels, activeIndex, onTabChanged }) => {
  const [activePanel, setActivePanel] = useState(activeIndex || 0);

  const onClick = i => {
    setActivePanel(i);

    if (onTabChanged) {
      onTabChanged(i);
    }
  };

  return (
    <div>
      <Headers>
        {panels.map((panel, i) => (
          <HeaderItem
            active={i === activePanel}
            onClick={() => onClick(i)}
            key={`tabHeader${i}`}
          >
            {panel[0]}
          </HeaderItem>
        ))}
      </Headers>
      <Body>{panels[activePanel][1]}</Body>
    </div>
  );
};

export default Tabs;
