import React, { useEffect, useState } from "react";
import { Select } from "@sussex/react-kit/elements";
import useVideoContext from "../../../hooks/useVideoContext";
import useLocalVideoToggle from "../../../hooks/useLocalVideoToggle";
import { resolutions } from "../../../connectionOptions";
import useCopy from "../../../hooks/useCopy";
import { datadogEvent } from "../../../datadog";

export default function SelectVideoQuality() {
  const {
    localTracks,
    localVideoOptions,
    replaceLocalVideoTrack,
  } = useVideoContext();
  const [isVideoEnabled] = useLocalVideoToggle();
  const localVideoTrack = localTracks.find(track =>
    track.name.includes("camera"),
  );
  const [qualityLabel, systemDefaultText] = useCopy([
    "sessioncontrols.quality",
    "sessioncontrols.systemdefault",
  ]);
  const labelCopy = useCopy(resolutions.map(r => r.labelKey));
  const [supportedResolutions, setSupportedResolutions] = useState([]);

  resolutions.forEach((r, i) => (r.label = labelCopy[i]));

  const onChange = e => {
    const dimensions = supportedResolutions[e.target.value].dimensions;
    const p = replaceLocalVideoTrack({ dimensions });
    if (p) {
      p.then(() => {
        datadogEvent({
          category: "media_settings",
          feature: "video_quality",
          event: "changed",
          component: "SessionControls.MediaSelection.SelectVideoQuality",
          metadata: { dimensions },
        });
      }).catch(err => {
        console.error("Could not change video quality", err);
      });
    }
  };

  useEffect(() => {
    if (!isVideoEnabled) {
      setSupportedResolutions([]);
      return;
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamTrack/getCapabilities#Browser_compatibility
    if (localVideoTrack.mediaStreamTrack.getCapabilities) {
      const capabilities = localVideoTrack.mediaStreamTrack.getCapabilities();
      setSupportedResolutions(
        resolutions.filter(
          r =>
            r.dimensions.width >= capabilities.width.min &&
            r.dimensions.width <= capabilities.width.max &&
            r.dimensions.height >= capabilities.height.min &&
            r.dimensions.height <= capabilities.height.max,
        ),
      );
    }
  }, [localVideoTrack, isVideoEnabled]);

  const options =
    supportedResolutions.length > 0
      ? supportedResolutions.map((r, i) => ({
          value: i,
          text: r.label,
        }))
      : [{ value: "default", text: systemDefaultText }];

  return (
    <Select
      label={qualityLabel}
      id="selectVideoQuality"
      disabled={!isVideoEnabled}
      options={options}
      value={
        supportedResolutions.findIndex(
          r =>
            r.dimensions.width === localVideoOptions.width &&
            r.dimensions.height === localVideoOptions.height,
        ) || ""
      }
      onChange={onChange}
    />
  );
}
