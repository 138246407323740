import React from "react";
import useVideoContext from "../../hooks/useVideoContext";
import ConnectionDroppedView from "../../pages/ConnectionDroppedView";

export default function ConnectionDroppedHandler({ children, isTherapist }) {
  const { connectionDropped } = useVideoContext();

  if (connectionDropped) {
    return <ConnectionDroppedView isTherapist={isTherapist} />;
  }

  return <>{children}</>;
}
