import React from "react";
import AvailableBackgrounds, { DefaultBackground } from "../../backgrounds";
import styled from "styled-components";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";

const Styled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-image: url("/backgrounds/full/${({ background }) => background}");
  background-size: cover;
  background-position: center center;
`;

const BackgroundImage = () => {
  const { room } = useWaitingRoomContext();
  const background = room.background || DefaultBackground;
  return <Styled background={AvailableBackgrounds[background]} />;
};

export default BackgroundImage;
