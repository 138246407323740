import React, { useEffect } from "react";
import SessionHistory from "../components/SessionHistory";
import { datadogSetPage } from "../datadog";

const View = () => {
  useEffect(() => {
    datadogSetPage("SessionHistory");
  }, []);

  return <SessionHistory />;
};

export default View;
