import React from "react";
import styled from "styled-components";
import { TextArea } from "@sussex/react-kit/elements";

const Wrapper = styled.div`
  word-break: break-word;
  background: ${({ theme }) => theme.colors.light};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 3px;
  padding: 20px;
`;

const Message = styled.div`
  color: ${({ theme }) => theme.colors.gray};
  margin: 20px 0;
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
`;

const MessageArea = ({ title, message, placeholder, onChange }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Message dangerouslySetInnerHTML={{ __html: message }} />
      <TextArea
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
      />
    </Wrapper>
  );
};

export default MessageArea;
