import React from "react";
import styled from "styled-components";
import { Checkbox } from "@sussex/react-kit/elements";
import useCopy from "../../hooks/useCopy";
import { datadogEvent } from "../../datadog";

const ClientTimerWrapper = styled.div`
  line-height: 1;
  span {
    margin-left: 10px;
    font-size: 16px;
  }
`;

function ClientTimerSettings({ onChange, clientTimer }) {
  const [clientTimerLabel] = useCopy(["settings.clientTimer"]);

  const handleClientTimerChange = e => {
    datadogEvent({
      category: "room_settings",
      feature: "show_session_timer",
      event: clientTimer ? "unchecked" : "checked",
      component: "RoomSettings.ClientTimerSettings",
    });

    onChange({ clientTimer: !clientTimer });
  };

  return (
    <ClientTimerWrapper>
      <Checkbox checked={clientTimer} onClick={handleClientTimerChange} />
      <span>{clientTimerLabel}</span>
    </ClientTimerWrapper>
  );
}

export default ClientTimerSettings;
