import { useState } from "react";
import { useWaitingRoomContext } from "../providers/WaitingRoomProvider";

export default function useCopyRoomUrl() {
  const [clicked, setClicked] = useState(false);
  const { getRoomUrl } = useWaitingRoomContext();
  const copyURL = () => {
    const el = document.createElement("textarea");
    el.value = getRoomUrl();
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setClicked(true);
    setTimeout(() => setClicked(false), 3000);
  };
  return { copyURL, clicked };
}
