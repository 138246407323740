import React from "react";
import { Link } from "@sussex/react-kit/elements";
import useCopyRoomUrl from "../../hooks/useCopyRoomUrl";
import { datadogEvent } from "../../datadog";

const CopyRoomUrl = ({ copyLinkText, copiedToClipboardText }) => {
  const { copyURL, clicked } = useCopyRoomUrl();

  const copyLink = () => {
    datadogEvent({
      category: "invitation",
      feature: "copy_link",
      event: "clicked",
      component: "InviteToSessionModal.CopyRoomUrl",
    });

    copyURL();
  };

  return (
    <Link onClick={copyLink}>
      {clicked ? copiedToClipboardText : copyLinkText}
    </Link>
  );
};

export default CopyRoomUrl;
