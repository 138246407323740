import React from "react";
import styled from "styled-components";

import { useHistory } from "react-router-dom";

const Inner = styled.div`
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.default};
  text-decoration: none;
  margin-bottom: 30px;
  cursor: pointer;
`;

export default function SPALink({ active, text, path, onClick }) {
  const history = useHistory();
  const handleClick = () => {
    if (!active && path) {
      history.push(path);
    }
    if (typeof onClick !== "undefined") {
      return onClick();
    }
  };
  return (
    <div onClick={handleClick}>
      <Inner>{text}</Inner>
    </div>
  );
}
