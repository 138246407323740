import React from "react";
import styled from "styled-components";
import IconButton from "./IconButton";
import Counter from "./Counter";
import { useInSessionWaitingRoomContext } from "../../InSessionWaitingRoom";

import icon from "../../../assets/waiting-room-icon.svg";
import useCopy from "../../../hooks/useCopy";
import { datadogEvent } from "../../../datadog";

const Icon = styled.img`
  width: 50%;
`;

export default function WaitingRoomToggleButton({
  waitingRoomOpen,
  setWaitingRoomOpen,
}) {
  const { notification } = useInSessionWaitingRoomContext();
  const [showWaitingRoomText] = useCopy(["sessioncontrols.showWaitingRoom"]);

  const onClick = e => {
    e.stopPropagation();
    setWaitingRoomOpen(!waitingRoomOpen);

    datadogEvent({
      category: "waiting_room",
      feature: "panel",
      event: waitingRoomOpen ? "closed" : "opened",
      component: "SessionControls.Buttons.WaitingRoomToggleButton",
    });
  };

  return (
    <IconButton
      onClick={onClick}
      id="toggleWaitingRoom"
      tooltip={showWaitingRoomText}
    >
      <Icon src={icon} alt={showWaitingRoomText} />
      {notification ? <Counter id="waitingRoomCount" /> : null}
    </IconButton>
  );
}
