import { useCallback, useState, useEffect, useContext } from "react";
import { NotificationContext } from "../../Notifications";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../../../hooks/useCopy";
import { useAppStateContext } from "../../../providers/AppStateProvider";
import { getParticipantInfo } from "../../../httpapi";

export default function useNotifyWaitingRoomUsers(isTherapist) {
  const {
    state: { waitingRoom },
  } = useAppStateContext();
  const { participants: waitingParticipants } = waitingRoom;
  const { addMessage } = useContext(NotificationContext);
  const [seenParticipants, setSeenParticipants] = useState(waitingParticipants);
  const [aUserText, inYourWaitingRoomText] = useCopy([
    "notifications.genericUserInWaitingRoom",
    "notifications.userInWaitingRoom",
  ]);

  const addNewUserMessage = useCallback(
    async p => {
      const result = await getParticipantInfo(p.participantId);
      const displayName =
        result.success === false ? aUserText : result.displayName;
      addMessage(
        swapCopyVariables(inYourWaitingRoomText, { NAME: displayName }),
      );
    },
    [addMessage, aUserText, inYourWaitingRoomText],
  );

  useEffect(() => {
    if (!isTherapist) {
      return;
    }
    const newUnseenParticipants = waitingParticipants.filter(
      p =>
        !seenParticipants.map(s => s.participantId).includes(p.participantId),
    );
    newUnseenParticipants.forEach(addNewUserMessage);
    if (newUnseenParticipants.length > 0) {
      setSeenParticipants(prev => prev.concat(newUnseenParticipants));
    }
  }, [
    addNewUserMessage,
    isTherapist,
    waitingParticipants,
    seenParticipants,
    inYourWaitingRoomText,
  ]);
}
