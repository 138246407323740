import React from "react";
import styled from "styled-components";
import IconButton from "./IconButton";
import useCopy from "../../../hooks/useCopy";
import OverflowMenu from "../../OverflowMenu";

const OverflowWrap = styled.div`
  & > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    width: 5px;
  }
`;

export default function SettingsButton({ settingsOpen, setSettingsOpen }) {
  const [tooltip] = useCopy(["sessioncontrols.options"]);
  const onClick = () => {
    setSettingsOpen(!settingsOpen);
  };

  return (
    <IconButton onClick={onClick} tooltip={tooltip}>
      <OverflowWrap>
        <OverflowMenu />
      </OverflowWrap>
    </IconButton>
  );
}
