/**
 * Component: AccountMenu
 *
 * The account menu component contains the initial view of
 * `Profile Name | ProfileImage`, that when clicked will open either a Dropdown
 * or a MobileNavigation menu with supporting account navigation items within.
 */
import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import ProfileImage from "./ProfileImage";
import { useWaitingRoomContext } from "../../../providers/WaitingRoomProvider";
import { useAppStateContext } from "../../../providers/AppStateProvider";

import Dropdown from "./Dropdown";
import ViewProfile from "./ViewProfile";
import MobileNavigation from "../../MobileNavigation";

const Wrapper = styled.div`
  position: absolute;
  top: 30px;
  right: 15px;
  display: flex;
  align-items: center;
  pointer-events: auto;
  cursor: pointer;
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    position: relative;
    top: unset;
    right: unset;
  }
`;

const Name = styled.div`
  display: none;
  color: white;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  @media (min-width: 960px) {
    display: block;
    margin-right: 10px;
  }
`;

const AccountMenu = () => {
  const { room } = useWaitingRoomContext();
  const [active, setActive] = useState(false);
  const {
    state: { accountNav },
  } = useAppStateContext();

  const {
    breakpoints: { desktop: desktopBreakpoint },
  } = useTheme();

  const query = window.matchMedia(`(min-width: ${desktopBreakpoint})`);

  const Menu = query.matches ? Dropdown : MobileNavigation;

  const handleOpen = e => {
    setActive(!active);
  };

  return (
    <>
      <Wrapper onClick={handleOpen}>
        <Name>{room.profileName}</Name>
        <ProfileImage src={room.therapistPhotoUrl} alt={room.profileName} />
      </Wrapper>
      {active && (
        <Menu
          id="account"
          onClose={() => setActive(false)}
          header={() => <ViewProfile />}
          label="Account"
          items={accountNav.children}
          showHeadings
        />
      )}
    </>
  );
};

export default AccountMenu;
