import React from "react";
import styled from "styled-components";
import AutoLinkText from "react-autolink-text2";
import useParticipantInfo from "../../hooks/useParticipantInfo";

const Bubble = styled.div`
  background: ${({ isSender, theme }) =>
    isSender ? theme.colors.chat.sent : theme.colors.chat.received};
  color: ${({ isSender, theme }) =>
    isSender ? "white" : theme.colors.default};
  margin: 15px 0;
  padding: 12px 20px;
  border-radius: ${({ isSender }) =>
    isSender ? "30px 30px 0 30px" : "30px 30px 30px 0"};
  text-align: left;
  word-break: break-word;
  a {
    overflow-wrap: break-word;
    text-decoration: none;
    color: ${({ isSender, theme }) =>
      isSender ? "white" : theme.colors.default};
    &:visited {
      color: ${({ isSender, theme }) =>
        isSender ? "white" : theme.colors.default};
      text-decoration: none;
    }
  }
`;

const Name = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const ChatBubble = ({ isSender, from, message, inSession }) => {
  // don't look up participant info if isSender
  const lookupId = isSender ? null : from;
  const { displayName } = useParticipantInfo(lookupId);

  const name = isSender ? from : displayName;
  const messageParts = message.split("\n");

  return (
    <div>
      <Bubble isSender={isSender}>
        {inSession && <Name>{name}</Name>}
        {messageParts.map((part, i) => (
          <React.Fragment key={i}>
            <AutoLinkText text={part} linkProps={{ target: "_blank" }} />
            {i < messageParts.length && <br />}
          </React.Fragment>
        ))}
      </Bubble>
    </div>
  );
};

export default ChatBubble;
