import React from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import addToCallIcon from "../../assets/add-to-call-icon.svg";

const Wrapper = styled.button`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  appearance: none;
  outline: none;
  background: none;
  padding: 0;

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
`;

const Icon = styled.img`
  width: 26px;
  height: 20px;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.colors.default};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const AddButton = ({ onClick, inSession, ...rest }) => {
  const [addText, startText] = useCopy(["chat.addText", "chat.startText"]);

  return (
    <Wrapper onClick={onClick} {...rest}>
      <Icon src={addToCallIcon} alt={inSession ? addText : startText} />
      <Text>{inSession ? addText : startText}</Text>
    </Wrapper>
  );
};

export default AddButton;
