import { useState, useEffect, useRef } from "react";

export default function useSelectedParticipant(room) {
  const [selectedParticipant, _setSelectedParticipant] = useState(null);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const setSelectedParticipant = participant =>
    _setSelectedParticipant(prevParticipant =>
      prevParticipant === participant ? null : participant,
    );

  useEffect(() => {
    const onDisconnect = () =>
      isMounted.current && _setSelectedParticipant(null);

    room.on("disconnected", onDisconnect);
    return () => {
      room.off("disconnected", onDisconnect);
    };
  }, [room]);

  return { selectedParticipant, setSelectedParticipant };
}
