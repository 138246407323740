import React from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import mutedIcon from "../../assets/muted-mic-icon-white.svg";

const Wrapper = styled.div`
  width: 20px;
  height: 20px;
  background: ${({ theme }) => theme.colors.red};
  background-position: center center;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  img {
    user-select: none;
    -webkit-user-drag: none;
    flex: 0 0 auto;
  }
`;

export default function Muted() {
  const [mutedText] = useCopy(["callscreen.muted"]);
  return (
    <Wrapper>
      <img src={mutedIcon} alt={mutedText} />
    </Wrapper>
  );
}
