// Ref: https://www.twilio.com/docs/api/errors
const TwilioErrors = {
  RoomCompleted: 53118,
  SignalingConnectionError: 53000,
  SignalingConnectionDisconnected: 53001,
  SignalingServerBusyError: 53006,
  RoomMaxParticipantsExceededError: 53105,
  RoomNotFoundError: 53106,
  MediaConnectionError: 53405,
};

export default TwilioErrors;
