import React from "react";
import styled from "styled-components";
import { TextInput } from "@sussex/react-kit/elements";
import useWindowSize from "../../hooks/useWindowSize";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const HostName = styled(TextInput)`
  border: 1px solid #dddddd;
`;

function RoomAlias({ roomAlias, error, onChange }) {
  const { width } = useWindowSize();

  return (
    <Wrapper>
      <HostName
        disabled
        length={window.location.hostname.length}
        value={`${window.location.hostname}/`}
        stacked={width < 768}
        adjacent={width >= 768}
      />
      <TextInput
        value={roomAlias}
        onChange={onChange}
        error={error}
        stacked={width < 768}
        adjacent={width >= 768}
      />
    </Wrapper>
  );
}

export default RoomAlias;
