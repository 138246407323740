import React, { useState, useCallback } from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import { isNotificationSupported } from "../../hooks/useDesktopNotifications";
import Banner from "../Banner";
import { Button } from "@sussex/react-kit/elements";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";
import { requestDismissDesktopNotifications } from "../../httpapi";
import { datadogEvent } from "../../datadog";
import icon from "../../assets/alert-icon.svg";

const DismissWrap = styled.span`
  margin-right: 10px;
`;

const DesktopNotificationsBanner = () => {
  const [permission, setPermission] = useState(
    isNotificationSupported() ? Notification.permission : "denied",
  );

  const {
    room: { desktopNotificationsDismissed },
  } = useWaitingRoomContext();

  const [message, enableText, disableText] = useCopy([
    "desktopNotifications.prompt",
    "desktopNotifications.enableText",
    "desktopNotifications.disableText",
  ]);

  const handleDismiss = useCallback(() => {
    datadogEvent({
      category: "desktop_notifications",
      event: "dismissed",
      component: "DestopNotificationsBanner",
    });

    requestDismissDesktopNotifications().then(({ success }) => {
      if (success) {
        setPermission("denied");
      }
    });
  }, []);

  const handleEnable = useCallback(() => {
    datadogEvent({
      category: "desktop_notifications",
      event: "enabled",
      component: "DestopNotificationsBanner",
    });

    // Safari receives a callback as argument on the requestPermission
    // method, but other browsers return a promise
    const result = Notification.requestPermission(setPermission);
    if (result && result.then) {
      result.then(setPermission).catch(console.error);
    }
  }, [setPermission]);

  if (
    // No Browser support
    desktopNotificationsDismissed ||
    permission === "granted" ||
    permission === "denied" ||
    !isNotificationSupported()
  ) {
    return null;
  }

  const Actions = () => (
    <>
      <DismissWrap>
        <Button
          onClick={handleDismiss}
          variant="white"
          thin
          retainWidthOnMobile
        >
          {disableText}
        </Button>
      </DismissWrap>
      <Button variant="blue" onClick={handleEnable} thin retainWidthOnMobile>
        {enableText}
      </Button>
    </>
  );

  return <Banner icon={icon} message={message} actions={Actions} />;
};

export default DesktopNotificationsBanner;
