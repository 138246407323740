import React from "react";
import styled from "styled-components";
import { Link } from "@sussex/react-kit/elements";
import useCopy from "../../hooks/useCopy";

const Label = styled.span`
  display: inline-block;
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 17px;
  }
`;

export default function TermsOfService() {
  const [agreeText, agreeLinkText] = useCopy(["tos.agree", "tos.agreeLink"]);
  const open = () => document.dispatchEvent(new Event("tos"));

  return (
    <>
      <div style={{ margin: "5px 0" }}>
        <Label>
          {agreeText}
          <Link onClick={open}>{agreeLinkText}</Link>
        </Label>
      </div>
    </>
  );
}
