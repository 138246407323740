import { useEffect } from "react";
import useVideoContext from "./useVideoContext";
import EventBus from "../eventBus";

export function useTherapistKicked() {
  const { room } = useVideoContext();

  useEffect(() => {
    // room.disconnect isn't always a valid callback, so wrap it.
    const kick = () => {
      room.disconnect();
    };

    EventBus.on("kick", kick);

    return () => {
      EventBus.off("kick", kick);
    };
  }, [room]);
}
