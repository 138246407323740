import { useEffect, useState, useRef } from "react";

/*
  Returns the participant that is sharing their screen (if any). This hook assumes that only one participant
  can share their screen at a time.
*/
export default function useScreenShareParticipant(
  room,
  setSelectedParticipant,
) {
  const [screenShareParticipant, setScreenShareParticipant] = useState();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (room.state === "connected") {
      const updateScreenShareParticipant = () => {
        // Screen track will still be active when unpublished event is fired.
        // Wrap in a setTimeout to ensure the track lists are correct.
        setTimeout(() => {
          if (!isMounted.current) return;

          const participant = Array.from(room.participants.values())
            // the screenshare participant could be the localParticipant
            .concat(room.localParticipant)
            .find(participant =>
              Array.from(participant.tracks.values()).find(track => {
                return (
                  track.trackName.includes("screen") && track.isTrackEnabled
                );
              }),
            );
          if (participant && participant !== room.localParticipant) {
            setSelectedParticipant(null);
          }
          setScreenShareParticipant(participant);
        });
      };
      updateScreenShareParticipant();

      room.on("trackPublished", updateScreenShareParticipant);
      room.on("trackUnpublished", updateScreenShareParticipant);
      room.on("participantDisconnected", updateScreenShareParticipant);

      // the room object does not emit 'trackPublished' events for the localParticipant,
      // so we need to listen for them here.
      room.localParticipant.on("trackPublished", updateScreenShareParticipant);
      room.localParticipant.on(
        "trackUnpublished",
        updateScreenShareParticipant,
      );
      return () => {
        room.off("trackPublished", updateScreenShareParticipant);
        room.off("trackUnpublished", updateScreenShareParticipant);
        room.off("participantDisconnected", updateScreenShareParticipant);

        room.localParticipant.off(
          "trackPublished",
          updateScreenShareParticipant,
        );
        room.localParticipant.off(
          "trackUnpublished",
          updateScreenShareParticipant,
        );
      };
    }
  }, [room, setSelectedParticipant]);

  return screenShareParticipant;
}
