import React from "react";
import styled, { css } from "styled-components";
import { Link } from "@sussex/react-kit/elements";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../../hooks/useCopy";
import videoBlocked from "../../assets/video-blocked-icon.svg";
import { LocalTrackErrors } from "../../providers/VideoProvider/useLocalTracks/useLocalTracks";

const Wrapper = styled.div`
  color: white;
  position: absolute;
  text-align: center;
  max-width: 90%;
`;

const Content = styled.div`
  padding: 40px 20px;
  ${({ overlay }) =>
    overlay &&
    css`
      background: ${({ theme }) => theme.colors.greyOverlay};
    `}
`;

const Header = styled.div`
  font-size: ${({ theme }) => theme.fontSize.normal};
  font-weight: bold;
  margin-bottom: 10px;
`;

const Body = styled.div`
  font-size: ${({ theme }) => theme.fontSize.normal};
  img {
    vertical-align: bottom;
  }
  button {
    ${Link.componentStyle.rules}
    color: white;
    text-decoration: underline;
  }
`;

const Error = ({ error, onMediaSettingsOpen }) => {
  const [
    mediaPermissionsHeader,
    mediaPermissionsBody,
    deviceInUseHeader,
    deviceInUseBody,
    deviceInUseVideo,
    deviceInUseAudio,
    deviceInUseBoth,
    deviceUnavailableHeader,
    deviceUnavailableBody,
    deviceUnavailableClick,
    deviceUnavailableVideo,
    deviceUnavailableAudio,
    deviceUnavailableBoth,
  ] = useCopy([
    "global.videoPermissionError.header",
    "global.videoPermissionError.body",
    "global.deviceInUseError.header",
    "global.deviceInUseError.body",
    "global.deviceInUseError.videoDevice",
    "global.deviceInUseError.audioDevice",
    "global.deviceInUseError.bothDevices",
    "global.deviceUnavailableError.header",
    "global.deviceUnavailableError.body",
    "global.deviceUnavailableError.clickAction",
    "global.deviceUnavailableError.videoDevice",
    "global.deviceUnavailableError.audioDevice",
    "global.deviceUnavailableError.bothDevices",
  ]);

  const getHeader = () => {
    if (error.code === LocalTrackErrors.Permissions) {
      return mediaPermissionsHeader;
    }
    if (error.code === LocalTrackErrors.Unavailable) {
      return swapCopyVariables(deviceUnavailableHeader, {
        DEVICE:
          error.device === "video"
            ? deviceUnavailableVideo
            : error.device === "audio"
            ? deviceUnavailableAudio
            : deviceUnavailableBoth,
      });
    }
    if (error.code === LocalTrackErrors.InUse) {
      return swapCopyVariables(deviceInUseHeader, {
        DEVICE:
          error.device === "video"
            ? deviceInUseVideo
            : error.device === "audio"
            ? deviceInUseAudio
            : deviceInUseBoth,
      });
    }
    return "";
  };

  const getBody = () => {
    if (error.code === LocalTrackErrors.Permissions) {
      return swapCopyVariables(mediaPermissionsBody, {
        CAMERA_ICON: <img src={videoBlocked} alt={mediaPermissionsHeader} />,
      });
    }
    if (error.code === LocalTrackErrors.Unavailable) {
      return swapCopyVariables(deviceUnavailableBody, {
        CLICK_ACTION: (
          <button onClick={onMediaSettingsOpen}>
            {deviceUnavailableClick}
          </button>
        ),
        DEVICE:
          error.device === "video"
            ? deviceUnavailableVideo
            : error.device === "audio"
            ? deviceUnavailableAudio
            : deviceUnavailableBoth,
      });
    }
    if (error.code === LocalTrackErrors.InUse) {
      return deviceInUseBody;
    }
    return "";
  };

  return (
    <Wrapper>
      <Content overlay={error.device === "audio"}>
        <Header>{getHeader()}</Header>
        <Body>{getBody()}</Body>
      </Content>
    </Wrapper>
  );
};

export default Error;
