import React, { useCallback, useState, useEffect } from "react";
import Session from "../pages/SessionView";
import ConnectionDroppedHandler from "../components/ConnectionDroppedHandler";
import Dashboard from "../components/Dashboard";
import Survey from "../components/Survey";
import { SessionMenuProvider } from "../providers/SessionMenuProvider";
import { DevicesProvider } from "../providers/DevicesProvider";
import { AudioAlertProvider } from "../providers/AudioAlertProvider";
import { VideoProvider } from "../providers/VideoProvider";
import useVideoContext from "../hooks/useVideoContext";
import { useAppStateContext, actions } from "../providers/AppStateProvider";
import { useChatContext } from "../components/Chat";
import CODES from "../twilioErrors";

const { SESSION_END } = actions;

const VideoView = ({ message, setSessionId }) => {
  const { connectFailed } = useVideoContext();
  const { dispatch, state } = useAppStateContext();
  const { session } = state;
  const { setActiveChannel } = useChatContext();

  useEffect(() => {
    if (connectFailed) {
      dispatch({ type: SESSION_END });
    }
  }, [connectFailed, dispatch]);

  const onDisconnect = useCallback(
    (room, error) => {
      if (!error || error.code === CODES.RoomCompleted) {
        setActiveChannel("");
        dispatch({ type: SESSION_END });
        if (room && room.sid) {
          setSessionId(room.sid);
        }
      }
    },
    [dispatch, setSessionId, setActiveChannel],
  );

  return !session.active ? (
    <Dashboard message={message} connectFailed={connectFailed} />
  ) : (
    <Session
      token={session.token}
      playNotification={false}
      isTherapist={true}
      onDisconnect={onDisconnect}
    />
  );
};

const TherapistRoomView = () => {
  const [message, setMessage] = useState(null);
  const [sessionId, setSessionId] = useState("");

  const onSurveyEnd = useCallback(
    msg => {
      setSessionId("");
      setMessage(msg);
    },
    [setSessionId, setMessage],
  );

  if (sessionId !== "") {
    return <Survey sessionId={sessionId} onSurveyEnd={onSurveyEnd} />;
  }

  return (
    <VideoProvider>
      <DevicesProvider>
        <ConnectionDroppedHandler isTherapist={true}>
          <AudioAlertProvider>
            <SessionMenuProvider>
              <VideoView message={message} setSessionId={setSessionId} />
            </SessionMenuProvider>
          </AudioAlertProvider>
        </ConnectionDroppedHandler>
      </DevicesProvider>
    </VideoProvider>
  );
};

export default TherapistRoomView;
