import React from "react";
import { Select } from "@sussex/react-kit/elements";
import {
  useDevicesContext,
  getVideoInputDevices,
} from "../../../providers/DevicesProvider";
import useCopy from "../../../hooks/useCopy";
import { useAppStateContext } from "../../../providers/AppStateProvider";
import { datadogEvent } from "../../../datadog";

export default function SelectVideoTrack() {
  const {
    devices,
    videoInputDevice,
    replaceVideoInputDevice,
    working,
  } = useDevicesContext();
  const videoInputDevices = getVideoInputDevices(devices);
  const [videoText] = useCopy(["sessioncontrols.video"]);
  const {
    state: { session },
  } = useAppStateContext();

  const onChange = e => {
    const parts = e.target.value.split("|");
    if (parts.length > 1) {
      const deviceId = parts[0];
      const groupId = parts[1];
      const device = videoInputDevices.find(
        d => d.deviceId === deviceId && d.groupId === groupId,
      );

      replaceVideoInputDevice(device, session.active);

      datadogEvent({
        category: "media_settings",
        feature: "video_input",
        event: "changed",
        component: "SessionControls.MediaSelection.SelectVideoTrack",
        metadata: {
          device: device.label,
        },
      });
    }
  };

  return (
    <Select
      label={videoText}
      id="selectVideoDevice"
      value={
        videoInputDevice
          ? videoInputDevice.deviceId + "|" + videoInputDevice.groupId
          : ""
      }
      options={videoInputDevices.map(d => ({
        value: d.deviceId + "|" + d.groupId,
        text: d.label,
      }))}
      onChange={onChange}
      disabled={working}
    />
  );
}
