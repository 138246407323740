import React, { useEffect } from "react";
import RoomSettings from "../components/RoomSettings";
import { datadogSetPage } from "../datadog";

const View = () => {
  useEffect(() => {
    datadogSetPage("RoomSettings");
  }, []);

  return <RoomSettings />;
};

export default View;
