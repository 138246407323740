import React from "react";
import styled from "styled-components";
import { Link, H2 } from "@sussex/react-kit/elements";
import useCopy from "../../hooks/useCopy";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { isMobile } from "@sussex/react-kit/utils";
import useWindowSize from "../../hooks/useWindowSize";

const Wrapper = styled.div`
  width: 100%;
`;

function ResetLink(props) {
  const [retryText] = useCopy(["checkinscreen.retryAction"]);
  return <Link {...props}>{retryText}</Link>;
}

function OpenSettingsLink(props) {
  const [checkSettingsText] = useCopy(["checkinscreen.checkSettingsAction"]);
  return <Link {...props}>{checkSettingsText}</Link>;
}

function ConnectionErrorView({ reset }) {
  const [headerText, bodyText1, bodyText2] = useCopy([
    "checkinscreen.connectionErrorHeader",
    "checkinscreen.connectionErrorBody1",
    "checkinscreen.connectionErrorBody2",
  ]);

  const variables = {
    RETRY_ACTION: <ResetLink onClick={reset} />,
  };

  return (
    <Wrapper>
      <H2>{headerText}</H2>
      <p>{swapCopyVariables(bodyText1, variables)}</p>
      <p>{bodyText2}</p>
    </Wrapper>
  );
}

function VideoErrorView({ reset, openSettings }) {
  const [
    headerText,
    bodyText1,
    bodyText1Mobile,
    bodyText1Small,
    bodyText2,
  ] = useCopy([
    "checkinscreen.videoErrorHeader",
    "checkinscreen.videoErrorBody1",
    "checkinscreen.videoErrorBody1Mobile",
    "checkinscreen.videoErrorBody1Small",
    "checkinscreen.videoErrorBody2",
  ]);
  const { width } = useWindowSize();

  const variables = {
    CHECK_ACTION: <OpenSettingsLink onClick={openSettings} />,
    RETRY_ACTION: <ResetLink onClick={reset} />,
  };

  const mainBodyText = isMobile
    ? swapCopyVariables(bodyText1Mobile, {
        RETRY_ACTION: <ResetLink onClick={reset} />,
      })
    : width < 768
    ? swapCopyVariables(bodyText1Small, variables)
    : swapCopyVariables(bodyText1, variables);

  return (
    <Wrapper>
      <H2>{headerText}</H2>
      <p>{mainBodyText}</p>
      <p>{bodyText2}</p>
    </Wrapper>
  );
}

export { ConnectionErrorView, VideoErrorView };
