import React, { useState, useEffect, useCallback, useRef } from "react";
import EventBus from "../../eventBus";
import styled from "styled-components";
import useWindowSize from "../../hooks/useWindowSize";
import { useChatContext } from "./ChatProvider";
import useVideoContext from "../../hooks/useVideoContext";
import { useAppStateContext } from "../../providers/AppStateProvider";
import useCopy from "../../hooks/useCopy";
import { H2 } from "@sussex/react-kit/elements";
import { SlideoutPanel } from "../SlideoutLayout";
import ChatLog from "./ChatLog";
import ChatFooter from "./ChatFooter";
import { datadogEvent } from "../../datadog";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ height }) => height + "px"};
`;

const BodyWrap = styled.div`
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
`;

const FooterWrap = styled.div`
  width: 100%;
  background-color: white;
`;

const Chat = ({ channel }) => {
  const [isOpen, setOpen] = useState(false);
  const { state } = useAppStateContext();
  const { sendMessage } = useVideoContext();
  const initialized = useRef(false);
  const [headerText, placeholder] = useCopy([
    "chatWindow.header",
    "chatWindow.placeholder",
  ]);

  const {
    getMessages,
    setActiveChannel,
    isActiveChannel,
    cleanMessages,
  } = useChatContext();

  const { height } = useWindowSize();
  const chatLog = getMessages(channel);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      cleanMessages(channel);
    }
  }, [channel, cleanMessages]);

  useEffect(() => {
    setOpen(isActiveChannel(channel));
  }, [channel, setOpen, isActiveChannel]);

  const onClose = useCallback(() => {
    setActiveChannel("");

    datadogEvent({
      category: "session_chat",
      feature: "panel",
      event: "closed",
      component: "Chat.SessionChat",
    });
  }, [setActiveChannel]);

  const sendChatMessage = message => {
    datadogEvent({
      category: "session_chat",
      feature: "send_message",
      event: "clicked",
      component: "Chat.SessionChat",
    });

    sendMessage({
      type: "chat",
      message: message,
    });

    EventBus.emit("chat", {
      from: state.displayName,
      channel: channel,
      message: message,
      self: true,
    });

    datadogEvent({
      category: "session_chat",
      event: "message_sent",
      component: "Chat.SessionChat",
    });
  };

  return (
    <SlideoutPanel
      active={isOpen}
      onClose={onClose}
      header={() => <H2>{headerText}</H2>}
      overlap={false}
      position="right"
    >
      <Wrap height={height - 77}>
        <BodyWrap>
          <ChatLog messages={chatLog} inSession={true} />
        </BodyWrap>
        <FooterWrap>
          <ChatFooter placeholder={placeholder} onSend={sendChatMessage} />
        </FooterWrap>
      </Wrap>
    </SlideoutPanel>
  );
};

export default Chat;
