/**
 * Hook: usePreventScroll
 *
 * Prevent the document from scrolling by setting a fixed value to the body.
 * Determine the current Y offset and ensure the page does not bounce around
 * when toggling on and off.
 */
import { useEffect } from "react";

const usePreventScroll = () => {
  useEffect(() => {
    const body = document.body;
    const scrollY = document.documentElement.style.getPropertyValue(
      "--scroll-y",
    );

    /**
     * Determine if the current page has a vertical scroll bar so that we can
     * force a scrollbar when we fix this page if we need to. This prevents the
     * page from bouncing horizontally when toggling on and off
     */
    const hasVScroll =
      document.body.clientHeight > document.documentElement.clientHeight;
    body.style.position = "fixed";
    body.style.overflowY = hasVScroll ? "scroll" : "auto";
    body.style.width = "100%";
    body.style.top = `-${scrollY}`;

    return () => {
      const scrollY = body.style.top;
      body.style.position = "";
      body.style.top = "";
      body.style.overflowY = "auto";
      body.style.width = "auto";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    };
  }, []);
};

export default usePreventScroll;
