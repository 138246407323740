import styled from "styled-components";

const Counter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.red};
  font-size: 12px;
  line-height: 20px;
  text-align: center;
`;

export default Counter;
