import React, { useState, useEffect } from "react";
import useCopy from "../../hooks/useCopy";
import useVideoContext from "../../hooks/useVideoContext";
import ConfirmationModal from "../ConfirmationModal";
import { requestEndSession } from "../../httpapi";
import { datadogEvent } from "../../datadog";

const DisconnectPrompt = () => {
  const { participants } = useVideoContext();
  const [sessionHasStarted, setSessionHasStarted] = useState(false);
  const [dismissed, setDismissed] = useState(false);

  const show = sessionHasStarted && participants.length === 0 && !dismissed;

  const [title, confirmText, message] = useCopy([
    "emptyRoomPrompt.title",
    "emptyRoomPrompt.confirmText",
    "emptyRoomPrompt.message",
  ]);

  const endSession = async () => {
    datadogEvent({
      category: "session",
      feature: "end",
      event: "clicked",
      component: "VideoSession.DisconnectPrompt",
    });

    const ended = await requestEndSession();

    if (ended) {
      datadogEvent({
        category: "session",
        event: "ended",
        component: "VideoSession.DisconnectPrompt",
      });
    }
  };

  const dismiss = () => {
    setDismissed(true);
  };

  useEffect(() => {
    if (!sessionHasStarted && participants.length > 0) {
      setSessionHasStarted(true);
    }
  }, [sessionHasStarted, participants]);

  return show ? (
    <ConfirmationModal
      onCancel={dismiss}
      onClose={dismiss}
      onConfirm={endSession}
      title={title}
      confirmText={confirmText}
    >
      {message}
    </ConfirmationModal>
  ) : null;
};

export default DisconnectPrompt;
