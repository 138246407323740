import { theme } from "@sussex/react-kit";

const sessionsTheme = {
  ...theme,
  breakpoints: {
    desktop: "768px",
  },
};

export default sessionsTheme;
