import React from "react";
import styled from "styled-components";
import IconButton from "./IconButton";

import icon from "../../../assets/end-call-icon.svg";
import useCopy from "../../../hooks/useCopy";

const Icon = styled.img`
  width: 62%;
`;

export default function ExitButton({ onClick }) {
  const [closeSessionText] = useCopy(["sessioncontrols.closeSession"]);
  return (
    <IconButton onClick={onClick} id="endSession" tooltip={closeSessionText}>
      <Icon src={icon} alt={closeSessionText} />
    </IconButton>
  );
}
