import { useCallback } from "react";
import useVideoContext from "./useVideoContext";

import { isMobile } from "@sussex/react-kit/utils";

export default function usePIP() {
  const { primaryVideoTrack } = useVideoContext();

  const pipEnabled = useCallback(() => {
    if (isMobile) {
      return false;
    }
    return typeof primaryVideoTrack?.requestPictureInPicture !== "undefined";
  }, [primaryVideoTrack]);

  const hasPIP = useCallback(() => {
    return document.pictureInPictureElement;
  }, []);

  const startPIP = useCallback(() => {
    primaryVideoTrack.onleavepictureinpicture = () => {
      setTimeout(() => {
        primaryVideoTrack.play();
      }, 0);
    };
    primaryVideoTrack.requestPictureInPicture();
  }, [primaryVideoTrack]);

  const stopPIP = useCallback(() => {
    return document.exitPictureInPicture();
  }, []);

  return {
    hasPIP,
    stopPIP,
    pipEnabled,
    startPIP,
  };
}
